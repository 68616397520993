import {
  ReservationStateEnum,
  UpdateUserInfoFormData,
  UpdateUserInfoRequest,
} from '@orascom/api-interfaces';
import { useContext, useState } from 'react';
import {
  Unit as UnitUtils,
  handleAttachMultipleReservationFiles,
} from '../../utils/unit.utils';
import { toast } from 'react-toastify';
import { FormikHelpers } from 'formik';
import { RESERVATION_CONTEXT } from '@orascom/common-components';

const getUpdateUserInfoInput = (
  formData: UpdateUserInfoFormData,
  customerId: string
): UpdateUserInfoRequest => {
  const mappedData: UpdateUserInfoRequest = {
    nationality_id: String(formData.nationality?.value),
    country_id: String(formData.country?.value),
    city_id: formData.city ? String(formData.city?.value) : '',
    city_name: formData.city ? String(formData.city?.label) : formData.cityName,
    district_id: '',
    district_name: formData.district,
    address: formData.address,
    occupation_code: formData.occupation?.value?.toString(),
    occupation_name: formData.occupation
      ? formData.occupation?.label
      : formData.occupationName,
    marital_status: formData.maritalStatus,
    national_id_number: formData.nationalIdNumber,
    passport_number: formData.passportNumber,
    has_joint_buyer: formData.jointBuyer,
    customer_id: customerId,
  };
  if (formData.jointBuyer) {
    mappedData.joint_buyer_first_name = formData.jointBuyerFirstName;
    mappedData.joint_buyer_last_name = formData.jointBuyerLastName;
    mappedData.joint_buyer_mobile_number = `+${formData.jointBuyerCountryCode}${formData.jointBuyerPhoneNumber}`;
    mappedData.joint_buyer_email = formData.jointBuyerEmail;
    mappedData.joint_buyer_country_id =
      formData.jointBuyerCountry?.value?.toString();
    mappedData.joint_buyer_nationality_id =
      formData.jointBuyerNationality?.value?.toString();
    mappedData.joint_buyer_city_id = formData.jointBuyerCity?.value?.toString();
    mappedData.joint_buyer_city_name = formData.jointBuyerCity
      ? formData.jointBuyerCity?.label
      : formData.jointBuyerCityName;
    mappedData.joint_buyer_district_name = formData.jointBuyerDistrict;
    mappedData.joint_buyer_occupation_code =
      formData.jointBuyerOccupation?.value?.toString();
    mappedData.joint_buyer_occupation_name = formData.occupation
      ? formData.jointBuyerOccupation?.label
      : formData.jointBuyerOccupationName;
    mappedData.joint_buyer_address = formData.jointBuyerAddress;
    mappedData.joint_buyer_marital_status = formData.jointBuyerMaritalStatus;
    mappedData.joint_buyer_national_id_number =
      formData.jointBuyerNationalIdNumber;
    mappedData.joint_buyer_passport_number = formData.jointBuyerPassportNumber;
    mappedData.joint_buyer_connection_role_code =
      formData.relationToBuyer?.value.toString() ?? undefined;
  }

  return mappedData;
};

const getAttachmentsInput = (formData: UpdateUserInfoFormData): File[] => {
  const attachedFiles: File[] = [];
  if (formData.identificationForm === 'nationalId') {
    attachedFiles.push(
      formData.nationalIdFrontImage as File,
      formData.nationalIdBackImage as File
    );
  } else {
    attachedFiles.push(formData.passportImage as File);
  }

  if (formData.jointBuyer) {
    if (formData.jointBuyerIdentificationForm === 'nationalId') {
      attachedFiles.push(
        formData.jointBuyerNationalIdFrontImage as File,
        formData.jointBuyerNationalIdBackImage as File
      );
    } else {
      attachedFiles.push(formData.jointBuyerPassportImage as File);
    }
  }

  return attachedFiles;
};

export const useUpdateUserInformation = (): [
  (
    formData: UpdateUserInfoFormData,
    formikHelpers: FormikHelpers<UpdateUserInfoFormData>
  ) => void,
  boolean,
  any
] => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState();
  const reservationContext = useContext(RESERVATION_CONTEXT);

  const handleSubmitForm = (
    formData: UpdateUserInfoFormData,
    formikHelpers: FormikHelpers<UpdateUserInfoFormData>
  ) => {
    if (
      (!reservationContext.sale?.customer_id &&
        !reservationContext?.reservationIds?.customerId) ||
      !reservationContext.reservationUnitDetails ||
      !reservationContext.sale
    ) {
      formikHelpers.setSubmitting(false);
      return;
    }

    const mappedData = getUpdateUserInfoInput(
      formData,
      reservationContext.sale?.customer_id ??
        reservationContext?.reservationIds?.customerId
    );
    const attachedFiles = getAttachmentsInput(formData);
    setLoading(true);

    let promise: Promise<any> = handleAttachMultipleReservationFiles(
      reservationContext.reservationUnitDetails?.id,
      reservationContext.sale?.id,
      attachedFiles
    );

    promise = promise.then(() => {
      return UnitUtils.updateReservedUserInfo(
        reservationContext.reservationUnitDetails?.id as number,
        reservationContext.sale?.id as number,
        mappedData
      );
    });

    promise.catch(() => {
      toast.error('Failed to Upload attachments');
      formikHelpers.setSubmitting(false);
      return Promise.reject();
    });

    promise = promise.then(() => {
      toast.success('info updated successfully');
      reservationContext?.setReservationState?.(
        ReservationStateEnum.RESERVATION_FORM
      );
    });

    promise
      .catch((error) => {
        toast.error('Failed to update user information');
        console.log('error', error);
        setErrors(error);
        formikHelpers.setSubmitting(false);
      })
      .finally(() => setLoading(false));
  };

  return [handleSubmitForm, loading, errors];
};

import styles from './footer.module.scss';
import { Country as CountryUtilities } from '../../utils/country.utils';
import { Country as CountryInterface } from '../../definitions/interfaces/country.interface';
import { useContext, useEffect, useState } from 'react';
import { ROUTES } from '../../api/routes';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/images/logo-horizontal-white.png';
import InstagramIcon from '../../assets/icons/instagram.svg?react';
import FacebookIcon from '../../assets/icons/facebook.svg?react';
import LinkedinIcon from '../../assets/icons/linkedin.svg?react';
import { CommonEventParameters } from '@orascom/utils';
import { USER_CONTEXT } from '../../contexts/user-context';
import { analyticsFooterContactUsCustomEvent } from '../../utils/analytics-events';

const socialLinks = [
  {
    name: 'facebook',
    Icon: FacebookIcon,
    to: 'https://www.facebook.com/OrascomDevelopment',
  },
  {
    name: 'linkedin',
    Icon: LinkedinIcon,
    to: 'https://www.linkedin.com/company/orascom-development',
  },
  {
    name: 'instagram',
    Icon: InstagramIcon,
    to: 'https://www.instagram.com/orascomdevelopment',
  },
];
export function Footer() {
  const { t } = useTranslation();
  const [countries, setCountries] = useState<CountryInterface[]>([]);
  const UserContext = useContext(USER_CONTEXT);

  const analyticsEventParams: CommonEventParameters = {
    userId: UserContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Shopper',
    pageName: location.pathname,
  };

  useEffect(() => {
    CountryUtilities.getCountries()
      .then((res) => setCountries(res))
      .catch((err) => console.log(err));
  }, []);
  return (
    <footer className={styles['container']}>
      <div className="container">
        <div className={styles['wrapper']}>
          <div className={styles['social']}>
            <img src={Logo} alt="logo" className={styles['logo']} />
            <ul className={styles['social-links']}>
              {socialLinks.map(({ name, Icon: SocialIcon, to }) => (
                <li key={name}>
                  <a
                    aria-label={name + ' link'}
                    href={to}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SocialIcon />
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles['countries']}>
            <p className={styles['list-title']}>{t('countries')}</p>
            <ul className={styles['list']}>
              {countries.map((country) => (
                <li key={country.id}>
                  <Link to={ROUTES.Country.getPath?.(country.slug, country.id)}>
                    {country.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className={styles['menu']}>
            <p className={styles['list-title']}>{t('menu')}</p>
            <ul className={styles['list']}>
              <li>
                <Link to={ROUTES.AboutUs.path}>{t('aboutUs')}</Link>
              </li>
              <li>
                <Link to={ROUTES.Investment.path}>{t('investments')}</Link>
              </li>
              <li>
                <Link to={ROUTES.FAQ.path}>{t('faqs')}</Link>
              </li>
              <li>
                <Link
                  to={ROUTES.ContactUs.path}
                  onClick={() =>
                    analyticsFooterContactUsCustomEvent(analyticsEventParams)
                  }
                >
                  {t('contactUs')}
                </Link>
              </li>
            </ul>
          </div>

          <ul className={styles['social-links-mobile']}>
            {socialLinks.map(({ name, Icon: SocialIcon, to }) => (
              <li key={name}>
                <a
                  aria-label={name + ' link'}
                  href={to}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SocialIcon />
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles['bottom']}>
          <div className={styles['bottom-links']}>
            <Link to={ROUTES.PrivacyPolicy.path}>{t('privacyPolicy')}</Link>
            <Link to={ROUTES.TermsAndConditions.path}>
              {t('termsAndConditions')}
            </Link>
          </div>
          <span>{`${new Date().getUTCFullYear()} ${t('rightsReserved')}`}</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

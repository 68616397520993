import { Country } from '../../definitions/interfaces/country.interface';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import styles from './destinations-slider.module.scss';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavyButton } from '../common/button/button';
import { SelectDropdown } from '@orascom/common-components';
import i18next from 'i18next';
import { analyticsExploreDestinationCustomEvent } from '../../utils/analytics-events';
import { useLocation } from 'react-router-dom';
import { USER_CONTEXT } from '../../contexts/user-context';

/* eslint-disable-next-line */
export interface DestinationsSliderProps {
  countries: Country[];
}

export function DestinationsSlider(props: Readonly<DestinationsSliderProps>) {
  const { t } = useTranslation();
  const location = useLocation();
  const userContext = useContext(USER_CONTEXT);
  const [activeDestinationIndex, setActiveDestinationIndex] =
    useState<number>(1);
  const [selectedCountry, setSelectedCountry] = useState<Country>(
    props.countries[0]
  );
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();
  const language = i18next.language;

  const countriesOptions = props.countries.map((country) => {
    return { label: country.name, value: country.id };
  });

  const updateSelectedCountry = (country: Country) => {
    setSelectedCountry(country);
    swiperInstance?.update();
    if (country.destinations.length > 1) {
      setActiveDestinationIndex(1);
    } else {
      setActiveDestinationIndex(0);
    }
  };
  const handleCountrySelectOnChange = (countryId: number) => {
    const selectedCountryOption = props.countries.find(
      (country) => country.id === countryId
    );

    if (
      selectedCountryOption &&
      selectedCountryOption?.destinations.length > 1
    ) {
      setActiveDestinationIndex(1);
    } else {
      setActiveDestinationIndex(0);
    }
    swiperInstance?.update();
    if (selectedCountryOption) {
      setSelectedCountry(selectedCountryOption);
    }
  };

  return (
    <div
      className={styles['wrapper']}
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 100%),url(${selectedCountry.destinations[activeDestinationIndex].cover_image})`,
      }}
    >
      <div className={styles['header']}>
        <h4 className="orascom__title">{t('discoverYourWorld')}</h4>
        <h5 className="orascom__sub-title">{t('destinations')}</h5>
      </div>
      <div className={styles['filter']}>
        {props.countries.map((country) => (
          <button
            key={country.id}
            type="button"
            onClick={() => updateSelectedCountry(country)}
            className={
              selectedCountry.id === country.id ? styles['active-filter'] : ''
            }
          >
            {country.name}
          </button>
        ))}
      </div>

      <div className={styles['dropdown-wrapper']}>
        <label htmlFor="country">{t('chooseCountry')}</label>
        <SelectDropdown
          name="country"
          options={countriesOptions}
          placeholder={t('country')}
          onChange={(val) => handleCountrySelectOnChange(Number(val?.value))}
          defaultValue={countriesOptions[0]}
          selectedOption={{
            label: selectedCountry.name,
            value: selectedCountry.id,
          }}
        />
      </div>
      <Swiper
        modules={[Pagination, Navigation]}
        spaceBetween={24}
        rewind={true}
        centeredSlides={true}
        initialSlide={1}
        slideToClickedSlide={true}
        speed={600}
        onSwiper={setSwiperInstance}
        onSlideChange={(swiper) =>
          setActiveDestinationIndex(swiper.activeIndex)
        }
        onUpdate={(swiper) => swiper.slideTo(activeDestinationIndex)}
        breakpoints={{
          320: {
            slidesPerView: 1.1,
          },
          768: {
            slidesPerView: 3,
          },
        }}
        pagination={{
          el: '.destinations-slider__pagination',
          dynamicBullets: false,
        }}
        dir={language === 'ar' ? 'rtl' : 'ltr'}
        key={language}
      >
        {selectedCountry.destinations?.map((destination) => (
          <SwiperSlide key={destination.id}>
            <div className={styles['card']}>
              <p className={styles['name']}>{destination.name}</p>
            </div>
          </SwiperSlide>
        ))}

        <p className={styles['description']}>
          {
            selectedCountry.destinations[activeDestinationIndex]
              .welcome_description
          }
        </p>
        <NavyButton
          asLink
          to={`/destination/${selectedCountry.destinations[activeDestinationIndex].slug}`}
          secondary
          onClick={() =>
            analyticsExploreDestinationCustomEvent({
              name: selectedCountry.destinations[activeDestinationIndex].slug,
              userId: userContext.user?.id.toString(),
              timestamp: Date.now().toString(),
              portal: 'Shopper',
              pageName: location.pathname,
              country: selectedCountry.slug,
              destination:
                selectedCountry.destinations[activeDestinationIndex].slug,
            })
          }
        >
          {t('exploreDestination')}
        </NavyButton>
        <div className="destinations-slider__pagination"></div>
      </Swiper>
    </div>
  );
}

export default DestinationsSlider;

import styles from './account-settings.module.scss';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { USER_CONTEXT } from '../../../contexts/user-context';
import { Loader } from '@orascom/common-components';

export function AccountSettings() {
  const { t } = useTranslation();

  const userContext = useContext(USER_CONTEXT);

  if (!userContext?.user) {
    return <Loader />;
  }

  return (
    <div className={`${styles['settings']} pre-delivery-container`}>
      <h1 className="orascom__sub-title ">{t('accountSettings')}</h1>
      <div className={styles['info']}>
        <div>
          <label>{t('fullName')}</label>
          <p className="name">{userContext.user.name}</p>
        </div>
        <div>
          <label>{t('email')}</label>
          <p>{userContext.user.email}</p>
        </div>
        <div>
          <label>{t('phoneNumber')}</label>
          <p>{userContext.user.phone}</p>
        </div>
      </div>
    </div>
  );
}

export default AccountSettings;

import {
  UnitReservationCity,
  UnitReservationConnection,
  UnitReservationCountry,
  UnitReservationNationality,
  UnitReservationOccupation,
} from '@orascom/api-interfaces';
import { useContext, useEffect, useState } from 'react';
import { Unit as UnitUtils } from '../../utils/unit.utils';
import { RESERVATION_CONTEXT } from '@orascom/common-components';

export const useReservationInformationPrerequisites = (): [
  UnitReservationNationality[],
  UnitReservationCountry[],
  UnitReservationCity[],
  UnitReservationOccupation[],
  UnitReservationConnection[],
  boolean
] => {
  const [loading, setLoading] = useState(false);
  const [nationalities, setNationalities] = useState<
    UnitReservationNationality[]
  >([]);
  const [countries, setCountries] = useState<UnitReservationCountry[]>([]);
  const [cities, setCities] = useState<UnitReservationCity[]>([]);
  const [occupations, setOccupations] = useState<UnitReservationOccupation[]>(
    []
  );
  const [connections, setConnections] = useState<UnitReservationConnection[]>(
    []
  );

  const reservationContext = useContext(RESERVATION_CONTEXT);
  const countryId =
    reservationContext?.reservationUnitDetails?.project.destination.country.id.toString();

  useEffect(() => {
    if (countryId) {
      setLoading(true);
      Promise.all([
        UnitUtils.getUnitReservationNationalities(countryId),
        UnitUtils.getUnitReservationCountries(countryId),
        UnitUtils.getUnitReservationCities(countryId),
        UnitUtils.getUnitReservationOccupations(countryId),
        UnitUtils.getUnitReservationConnections(countryId),
      ])
        .then((res) => {
          setNationalities(res[0]);
          setCountries(res[1]);
          setCities(res[2]);
          setOccupations(res[3]);
          setConnections(res[4]);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [countryId]);

  return [nationalities, countries, cities, occupations, connections, loading];
};

/* eslint-disable @nx/enforce-module-boundaries */
import { useContext, useEffect, useState } from 'react';
import { USER_CONTEXT } from '../../../contexts/user-context';
import { getUserSaleByUnitId } from '../../../utils/authentication.utils';
import { SaleInstallmentsResponse } from '@orascom/api-interfaces';
import { Unit as UnitUtils } from '../../../utils/unit.utils';
import { UserReservationSale } from '../../../definitions/interfaces/common.interface';

export const useUnitPayment = (
  unitId: string | undefined
): [
  unitInstallments: SaleInstallmentsResponse | undefined,
  loading: boolean,
  sale: UserReservationSale | undefined
] => {
  const { userReservationDetails } = useContext(USER_CONTEXT);
  const [sale, setSale] = useState<UserReservationSale>();
  const [loading, setLoading] = useState(true);
  const [unitInstallments, setUnitInstallments] =
    useState<SaleInstallmentsResponse>();

  useEffect(() => {
    if (userReservationDetails && unitId) {
      const userSale = getUserSaleByUnitId(
        userReservationDetails,
        unitId.toString()
      );
      setSale(userSale);

      if (userSale) {
        UnitUtils.getSaleInstallments(
          userSale.sale_source_id,
          userSale.unit.project.destination.country.slug
        )
          .then((res) => {
            setUnitInstallments(res);
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  }, [unitId, userReservationDetails]);

  return [unitInstallments, loading, sale];
};

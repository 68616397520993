import Numeral from 'numeral';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import { GoldButton, NavyButton } from '../../components/common/button/button';
import { UNIT_COMPARE_CONTEXT } from '../../contexts/unit-comparison.context';
import styles from './compare-properties.module.scss';
import closeIcon from '../../assets/icons/close.svg';
import leftArrowIcon from '../../assets/icons/left-arrow.svg';
import addToCompareIcon from '../../assets/icons/add-to-compare.svg';
import { Unit as UnitUtilities } from '../../utils/unit.utils';
import { UnitCompareInterface } from '@orascom/api-interfaces';
import { Loader, Modal } from '@orascom/common-components';
import ContactUsForm from '../../components/common/contact-us-form/contact-us-form';
import UnitPaymentCalculator from '../../components/common/unit-payment-calculator/unit-payment-calculator';
import { analyticsUnitsAddedToCompareCustomEvent } from '../../utils/analytics-events';
import { showReservationButton } from '../../definitions/consts/envConstants';
import { Helmet } from 'react-helmet-async';
import getDocTitle from '../../utils/getDocTitle';
import { USER_CONTEXT } from '../../contexts/user-context';
import { useCurrencyConverter } from '@orascom/utils';

const compareDocTitle = getDocTitle('Compare Properties');
export function CompareProperties() {
  const unitContext = useContext(UNIT_COMPARE_CONTEXT);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [compareUnits, setCompareUnits] = useState<UnitCompareInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [unitInterestedIn, setUnitInterestedIn] =
    useState<UnitCompareInterface>();
  const [showInterestModal, setShowInterestModal] = useState(false);
  const { convertToSelectedCurrency } = useCurrencyConverter();

  const location = useLocation();
  const userContext = useContext(USER_CONTEXT);

  const handleShowInterest = (unit: UnitCompareInterface) => {
    setUnitInterestedIn(unit);
    setShowInterestModal(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    setShowInterestModal(false);
    document.body.style.overflow = '';
  };

  useEffect(() => {
    if (unitContext.units?.length) {
      setLoading(true);
      analyticsUnitsAddedToCompareCustomEvent({
        name: `units added to compare: ${unitContext.units?.toString()}`,
        userId: userContext.user?.id.toString(),
        timestamp: Date.now().toString(),
        portal: 'Shopper',
        pageName: location.pathname,
      });
      UnitUtilities.getCompareUnits(unitContext.units)
        .then((res) => setCompareUnits(res))
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setLoading(false));
    }
  }, [unitContext.units]);

  const handleRemoveUnit = (id: number) => {
    setCompareUnits(compareUnits.filter((unit) => unit.id !== id));
    unitContext.removeUnit?.(id);
  };
  const handleClear = () => {
    compareUnits.forEach((unit) => unitContext.removeUnit?.(unit.id));
    setCompareUnits([]);
  };
  const CompareUnits = (length: number) => {
    let className = '';
    switch (length) {
      case 1:
        className = styles['table--single'];
        break;
      case 2:
        className = styles['table--two'];
        break;
      case 4:
        className = styles['table--four'];
        break;
      case 5:
        className = styles['table--five'];
        break;
      case 6:
        className = styles['table--six'];
        break;
    }
    return className;
  };
  const tableClass = `${styles['table']} ${CompareUnits(compareUnits.length)}`;

  if (loading) {
    return <Loader />;
  }

  // check if any unit has facilities
  const showFacilities = compareUnits?.reduce(
    (acc, unit) => acc || unit.facilities.length > 0,
    false
  );

  return (
    <div className={styles['wrapper']}>
      <Helmet>
        <title>{compareDocTitle}</title>
        <meta property="og:title" content={compareDocTitle} />
        <meta name="description" content={compareDocTitle} />
        <meta property="og:description" content={compareDocTitle} />
      </Helmet>
      <div className="container">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className={styles['back-btn']}
        >
          <img src={leftArrowIcon} role="presentation" alt="" />
          {t('backToPreviousPage')}
        </button>
        <div className={styles['title']}>
          <h1>
            {t('compareProperties', {
              count: compareUnits?.length,
            })}
          </h1>
          {compareUnits.length > 0 ? (
            <button
              type="button"
              onClick={handleClear}
              className={styles['title-clear']}
            >
              {t('clearAll')}
            </button>
          ) : null}
        </div>
      </div>
      {compareUnits.length > 0 ? (
        <table className={tableClass}>
          <tbody>
            <tr>
              <td>
                <span>{t('property')}</span>
                {compareUnits.length < 6 && compareUnits.length >= 1 ? (
                  <div className={styles['add-button-column']}>
                    <button
                      type="button"
                      className={styles['add-button']}
                      onClick={() => navigate(ROUTES.AvailableUnits.path)}
                    >
                      <img src={addToCompareIcon} role="presentation" alt="" />
                    </button>
                  </div>
                ) : null}
              </td>
              {compareUnits?.map((unit) => (
                <td key={unit.id}>
                  <Link
                    to={ROUTES.UnitDetails?.getPath?.(unit.id)}
                    className={styles['card']}
                  >
                    <button
                      className={styles['card-remove']}
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleRemoveUnit(unit.id);
                      }}
                    >
                      <img src={closeIcon} alt="" role="presentation" />
                    </button>
                    <img
                      className={styles['card-cover-image']}
                      src={unit.cover_image}
                      alt={unit.unit_type.name}
                      loading="lazy"
                    />

                    <div className={styles['card-info']}>
                      <p>
                        {`${unit.project.destination.name} - ${unit.project.name}`}
                      </p>
                      <p>{unit.unit_type.name}</p>
                    </div>
                  </Link>
                </td>
              ))}
            </tr>
            <tr>
              <td>{t('destination')}</td>
              {compareUnits?.map((unit) => (
                <td key={unit.id}>
                  <Link
                    to={ROUTES.Destination.getPath?.(
                      unit.project.destination.slug
                    )}
                    className={styles['destination-link']}
                  >
                    {unit.project.destination.name}
                  </Link>
                </td>
              ))}
            </tr>
            <tr>
              <td>{t('builtUpArea')}</td>
              {compareUnits?.map((unit) => (
                <td key={unit.id}>
                  {' '}
                  {unit.built_up_area && unit.built_up_area > 0
                    ? `${unit.built_up_area} ㎡`
                    : null}
                </td>
              ))}
            </tr>
            <tr>
              <td>{t('bedrooms')}</td>
              {compareUnits?.map((unit) => (
                <td key={unit.id}>
                  {' '}
                  {unit.bedrooms > 0 ? unit.bedrooms : null}
                </td>
              ))}
            </tr>
            <tr>
              <td>{t('bathrooms')}</td>
              {compareUnits?.map((unit) => (
                <td key={unit.id}>
                  {unit.bathrooms > 0 ? unit.bathrooms : null}
                </td>
              ))}
            </tr>
            {showFacilities && (
              <tr>
                <td>{t('featuresAndAmenities')}</td>
                {compareUnits?.map((unit) => (
                  <td key={unit.id}>
                    <ul>
                      {unit.facilities.map((facility) => (
                        <li
                          key={`${unit.id}-${facility.title}`}
                          className={styles['facility']}
                        >
                          <img src={facility.icon} role="presentation" alt="" />
                          <span>{facility.title}</span>
                        </li>
                      ))}
                    </ul>
                  </td>
                ))}
              </tr>
            )}

            <tr>
              <td>{t('unitPrice')}</td>
              {compareUnits?.map((unit) => {
                const { price, currency } = convertToSelectedCurrency(
                  unit.price,
                  unit.currency
                );
                return (
                  <td key={unit.id} className={styles['price']}>
                    {Numeral(price).format('0,0')} {currency}
                  </td>
                );
              })}
            </tr>

            <tr className={styles['master-plan-row']}>
              <td>{t('masterPlan')}</td>
              {compareUnits?.map((unit) =>
                unit.masterplan_image ? (
                  <td key={unit.id} className={styles['master-plan']}>
                    <div className={styles['master-plan-image']}>
                      <img
                        src={unit.masterplan_image}
                        alt="master-plan"
                        loading="lazy"
                      />
                    </div>
                  </td>
                ) : (
                  <td key={unit.id}>{t('noImageFound')}</td>
                )
              )}
            </tr>
            <tr className={styles['payment-plan-row']}>
              <td>{t('paymentPlan')}</td>
              {compareUnits?.map((unit) => (
                <td key={unit.id} className={styles['payment-plan']}>
                  <div>
                    <UnitPaymentCalculator
                      unitCountry={unit.project.destination.country.slug}
                      saveButton={
                        <GoldButton
                          onClick={() => {
                            console.log('hi');
                          }}
                        >
                          {t('saveAndProceedToCheckout')}
                        </GoldButton>
                      }
                      comparePage={true}
                      hideDetails
                      unitId={unit.id}
                    />
                  </div>
                </td>
              ))}
            </tr>
            <tr>
              <td></td>
              {compareUnits?.map((unit) => (
                <td key={unit.id} className={styles['buttons']}>
                  {showReservationButton && !unit.is_commercial && (
                    <GoldButton
                      asLink
                      to={ROUTES.ReserverProperty.getPath?.(unit.id)}
                    >
                      {t('reserveUnit')}
                    </GoldButton>
                  )}
                  <NavyButton onClick={() => handleShowInterest(unit)}>
                    {t('submitInterest')}
                  </NavyButton>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      ) : (
        <div className={styles['empty-compare']}>
          <p>{t('noPropertiesToCompare')}</p>
          <GoldButton onClick={() => navigate(ROUTES.AvailableUnits.path)}>
            {t('browseUnits')}
          </GoldButton>
        </div>
      )}

      <Modal
        show={showInterestModal}
        onClose={handleCloseModal}
        title={t('submitYourInterest')}
      >
        <ContactUsForm
          title={t('leaveYourContactInfo')}
          destination={unitInterestedIn?.project.destination.slug}
          country={Number(unitInterestedIn?.project.destination.country.id)}
          unitId={Number(unitInterestedIn?.id)}
          interestForm
          onCloseModal={handleCloseModal}
        />
      </Modal>
    </div>
  );
}

export default CompareProperties;

import {
  ReferralDetails,
  ReservationStateEnum,
  UnitPaymentTerms,
  UnitReservationDetails,
  UserReservationIds,
} from '@orascom/api-interfaces';
import { Loader, RESERVATION_CONTEXT } from '@orascom/common-components';
import { useContext, useEffect, useState } from 'react';
import MinimalFooter from '../../components/common/minimal-footer/minimal-footer';
import { SecondaryHeader } from '../../components/header/header';
import ReservePropertyLayout from '../../components/reserve-property-layout/reserve-property-layout';
import { Unit as UnitUtils } from '../../utils/unit.utils';
import { USER_CONTEXT } from '../../contexts/user-context';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  getReservationLeadIdOrCustomerId,
  getUserSaleByUnitId,
} from '../../utils/authentication.utils';
import { ROUTES } from '../../api/routes';
import {
  getPaymentDetailsFromPaymobParams,
  unitIdFromUserSales,
} from '../../utils/unit-sale.utils';
import { UserReservationSale } from '../../definitions/interfaces/common.interface';
import { Helmet } from 'react-helmet-async';
import getDocTitle from '../../utils/getDocTitle';

export function ReserveProperty() {
  const { t } = useTranslation();
  const [reservationState, setReservationState] =
    useState<ReservationStateEnum>();
  // const [selectedPaymentPlan, setSelectedPaymentPlan] =
  //   useState<PaymentPlanInterface>();

  const [selectedPaymentTerms, setSelectedPaymentTerms] =
    useState<UnitPaymentTerms>();
  const [loading, setLoading] = useState(true);

  const [reservationUnitDetails, setReservationUnitDetails] =
    useState<UnitReservationDetails>();

  const [sale, setSale] = useState<UserReservationSale>();

  const [reservationIds, setReservationIds] = useState<UserReservationIds>();

  const [referral, setReferral] = useState<ReferralDetails>();

  const userContext = useContext(USER_CONTEXT);

  const { unitId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (!userContext.user) {
      navigate(`${ROUTES.Register.path}?redirect=${window.location.pathname}`);
    }
  }, [userContext.user]);

  useEffect(() => {
    // Handle Paymob redirect
    if (unitId === 'paymob' && userContext?.userReservationDetails) {
      const paymentDetails = getPaymentDetailsFromPaymobParams(
        window.location.search
      );
      const unitSaleId = unitIdFromUserSales(
        userContext?.userReservationDetails?.sales ?? [],
        Number(paymentDetails?.saleID)
      );

      if (unitSaleId) {
        navigate(ROUTES.ReserverProperty.getPath?.(unitSaleId.toString()));
      } else {
        navigate(ROUTES.Home.path);
      }
    }
    if (userContext?.userReservationDetails && reservationUnitDetails) {
      const destinationUserDetails = getReservationLeadIdOrCustomerId(
        userContext.userReservationDetails,
        reservationUnitDetails?.project.destination.id.toString()
      );

      setReservationIds(destinationUserDetails);

      const userSale = getUserSaleByUnitId(
        userContext?.userReservationDetails,
        unitId
      );
      const updatedReservationState = UnitUtils.getSaleReservationState(
        userSale?.status
      );
      setReservationState(updatedReservationState);

      if (userSale && userSale?.crm_status !== 'canceled') {
        setSale(userSale);
        if (unitId && !isNaN(Number(unitId))) {
          setLoading(true);
          UnitUtils.getUnitPayments(unitId)
            .then((terms) => {
              setSelectedPaymentTerms(
                terms.find(
                  (term) =>
                    term.paymentplan_id === userSale.paymentplan_source_id
                )
              );
            })
            .catch((err) => console.log(err))
            .finally(() => {
              setLoading(false);
            });
        }
      }
    }
  }, [reservationUnitDetails, userContext?.userReservationDetails]);

  useEffect(() => {
    const userSale = getUserSaleByUnitId(
      userContext?.userReservationDetails,
      unitId
    );
    if (userSale?.unit && !reservationUnitDetails) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setReservationUnitDetails?.(userSale?.unit as any);
    } else if (unitId && !isNaN(Number(unitId))) {
      UnitUtils.getUnitReservationDetails(unitId)
        .then((res) => {
          setReservationUnitDetails?.(res);
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }
  }, [unitId, userContext?.userReservationDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [reservationState]);

  if (loading || userContext?.isLoadingUserReservationDetails) {
    return <Loader />;
  }
  const reserveDocTitle = getDocTitle('Reserve Property');
  return (
    <RESERVATION_CONTEXT.Provider
      value={{
        reservationState,
        setReservationState,
        selectedPaymentTerms,
        setSelectedPaymentTerms,
        reservationUnitDetails,
        setReservationUnitDetails,
        reservationIds,
        setReservationIds,
        sale,
        setSale,
        referral,
        setReferral,
      }}
    >
      <Helmet>
        <title>{reserveDocTitle}</title>
        <meta property="og:title" content={reserveDocTitle} />
        <meta name="description" content={reserveDocTitle} />
        <meta property="og:description" content={reserveDocTitle} />
      </Helmet>
      <SecondaryHeader />
      {!unitId || isNaN(Number(unitId)) ? (
        <div className="container">
          <h2>{t('noUnitsFound')}</h2>
        </div>
      ) : (
        <ReservePropertyLayout />
      )}

      <MinimalFooter showLinks reservation />
    </RESERVATION_CONTEXT.Provider>
  );
}

export default ReserveProperty;

/* eslint-disable @nx/enforce-module-boundaries */
import { GoldButton } from '../common/button/button';
// eslint-disable-next-line import/namespace
import UnitPaymentCalculator from '../common/unit-payment-calculator/unit-payment-calculator';
import { useContext, useEffect, useState } from 'react';
import {
  AddDraftSaleParams,
  CountriesEnum,
  ReservationStateEnum,
  UnitPaymentTerms,
  UnitReservationDetails,
  // UserReservationSale,
} from '@orascom/api-interfaces';
import styles from './payment-plan-form.module.scss';
import { useTranslation } from 'react-i18next';
import { Unit as UnitUtils } from '../../utils/unit.utils';
import { useLocation, useParams } from 'react-router-dom';
import ClockIcon from '../../assets/icons/clock.svg';

import { toast } from 'react-toastify';
import { Loader, RESERVATION_CONTEXT } from '@orascom/common-components';
import MontenegroUnitPaymentPlan from 'libs/common-components/src/lib/montenegro-unit-payment-plan/montenegro-unit-payment-plan';
import {
  CommonEventParameters,
  usePaymentPlanCalculator,
} from '@orascom/utils';
import { USER_CONTEXT } from '../../contexts/user-context';
import { analyticsSaveAndProceedToCheckoutEvent } from '../../utils/analytics-events';
interface PaymentPlanFormProps {
  readTerms: boolean;
  acknowledgedNonRefundable: boolean;
  showTermsModal: boolean;
  reservationUnitDetails?: UnitReservationDetails;
  selectedPaymentTerms?: UnitPaymentTerms;
  handleTermsCheckboxClick: () => void;
  handleAcknowledgeCheckboxClick: () => void;
  handleSelectPaymentTerms: (paymentTerms: UnitPaymentTerms) => void;
  unitCountry: CountriesEnum | undefined;
}

export function PaymentPlanForm(props: Readonly<PaymentPlanFormProps>) {
  const { t } = useTranslation();
  const UserContext = useContext(USER_CONTEXT);
  const { setReservationState, reservationIds, setSale, referral } =
    useContext(RESERVATION_CONTEXT);

  const [submitting, setSubmitting] = useState(false);
  const [reservationFeeError, setReservationFeeError] = useState(false);
  const [reservationFeeValue, setReservationFeeValue] = useState(0);

  const [paymentTerms, setPaymentTerms] = useState<UnitPaymentTerms[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const unitId = useParams<{ unitId: string }>().unitId;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const brokerId = queryParams.get('brokerId');

  const updateReservationFeeError = (reservationFeeErrorState: boolean) => {
    setReservationFeeError(reservationFeeErrorState);
  };
  const updateReservationFeeValue = (reservationFeeValueState: number) => {
    setReservationFeeValue(reservationFeeValueState);
  };
  const { setSelectedPaymentTerms } = usePaymentPlanCalculator();

  const analyticsEventParams: CommonEventParameters = {
    userId: UserContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Shopper',
    pageName: location.pathname,
  };

  useEffect(() => {
    if (!unitId || props.unitCountry !== CountriesEnum.MONTENEGRO) {
      setLoading(false);
      return;
    }
    if (props.unitCountry === CountriesEnum.MONTENEGRO) {
      UnitUtils.getUnitPayments(unitId)
        .then((terms) => {
          setPaymentTerms(terms);
          if (props.handleSelectPaymentTerms) {
            props.handleSelectPaymentTerms(terms[0]);
          }
          setSelectedPaymentTerms(terms[0]);
        })
        .catch(() => setError(true))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [unitId]);

  const handleCreateDraftSale = () => {
    if (
      props.reservationUnitDetails &&
      props.selectedPaymentTerms &&
      setSale &&
      reservationIds
    ) {
      const draftSaleData: AddDraftSaleParams = {
        customer_source_id: reservationIds.customerId
          ? reservationIds.customerId
          : '',
        lead_source_id: reservationIds.leadId ? reservationIds.leadId : '',
        broker_id: brokerId ?? null,
        referral_unit_name: referral?.unitName ?? '',
        referral_number: referral?.mobileNumber ?? '',
        paymentplan_source_id: props.selectedPaymentTerms.paymentplan_id,
        reservation_amount: reservationFeeValue.toString(),
      };
      setSubmitting(true);
      UnitUtils.addDraftSale(props.reservationUnitDetails.id, draftSaleData)
        .then((res) => {
          setReservationState?.(ReservationStateEnum.PAY_RESERVATION_FEE);
          setSale({
            crm_status: null,
            portal_sale: true,
            customer_id: res.customer_source_id,
            sale_id: res.sale_source_id,
            unit_id: props.reservationUnitDetails?.id as number,
            destination: props.reservationUnitDetails?.project
              .destination as any,
            ...res,
          });
          analyticsSaveAndProceedToCheckoutEvent(analyticsEventParams);
        })
        .catch((err) => {
          console.error(err);
          toast.error('This unit is no longer available for reservation');
        })
        .finally(() => setSubmitting(false));
    }
  };

  if (submitting) {
    return <Loader />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Something went wrong</div>;
  }

  const montenegroPaymentPlan = loading ? (
    <Loader />
  ) : (
    <MontenegroUnitPaymentPlan
      portal="Shopper"
      paymentTerms={paymentTerms}
      reservationPage
      readTerms={props.readTerms}
      updateReadTermsState={props.handleTermsCheckboxClick}
      acknowledgedNonRefundable={props.acknowledgedNonRefundable}
      updateAcknowledgedNonRefundableState={
        props.handleAcknowledgeCheckboxClick
      }
      handleSelectPaymentTerms={props.handleSelectPaymentTerms}
      updateReservationFeeValue={updateReservationFeeValue}
      saveButton={
        <GoldButton
          disabled={
            !props.readTerms ||
            !props.acknowledgedNonRefundable ||
            reservationFeeError
          }
          onClick={handleCreateDraftSale}
        >
          {t('saveAndProceedToCheckout')}
        </GoldButton>
      }
    />
  );

  return (
    <div className={styles['wrapper']}>
      <h5 className={styles['title']}>{t('paymentPlanCalculator')}</h5>
      {props.unitCountry === CountriesEnum.MONTENEGRO ? (
        montenegroPaymentPlan
      ) : (
        <UnitPaymentCalculator
          unitCountry={props.unitCountry}
          saveButton={
            <GoldButton
              disabled={
                !props.readTerms ||
                !props.acknowledgedNonRefundable ||
                reservationFeeError
              }
              onClick={handleCreateDraftSale}
            >
              {t('saveAndProceedToCheckout')}
            </GoldButton>
          }
          terms_and_conditions={true}
          readTerms={props.readTerms}
          acknowledgedNonRefundable={props.acknowledgedNonRefundable}
          updateAcknowledgedNonRefundableState={
            props.handleAcknowledgeCheckboxClick
          }
          updateReadTermsState={props.handleTermsCheckboxClick}
          hideDetails
          handleSelectPaymentTerms={props.handleSelectPaymentTerms}
          reservationPage
          updateReservationFeeError={updateReservationFeeError}
          updateReservationFeeValue={updateReservationFeeValue}
          reservationFeeValue={reservationFeeValue}
        />
      )}
      <p className={styles['disclaimer']}>
        <img src={ClockIcon} role="presentation" alt="" />
        <p>
          <span>{t('unitWillBeReservedFor')}</span>
        </p>
      </p>
    </div>
  );
}

export default PaymentPlanForm;

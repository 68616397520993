import { useTranslation } from 'react-i18next';
import {
  FiltersEnums,
  PriceSlider,
  PriceValue,
  SortValuesEnums,
  UnitFilters,
} from '@orascom/api-interfaces';
import { useWindowDimensions } from '@orascom/utils';
import { useSearchParams } from 'react-router-dom';
import searchIcon from '../../../assets/icons/search.svg';
import PriceRangeSlider from '../price-range-slider/price-range-slider';
import { NavyButton } from '../button/button';
import { MinMaxDropdown, Modal } from '@orascom/common-components';
import styles from './broker-filters.module.scss';
import { MultiFilterDropdown, SingleFilterDropdown } from './filter-dropdown';
import {
  SortByOptions,
  convertToOptionsWithSlug,
  convertNumFiltersToOptions,
  convertStringFiltersToOptions,
  handleClearAllFilters,
} from './filters-utils';
import { useState } from 'react';
import OnResaleButton from './on-resale-button';
import { debounce } from '../debounce/debounce';
import CloseIcon from '../../../assets/icons/close.svg';

interface UnitsFiltersProps {
  portal: 'broker' | 'sales-man' | 'shopper';
  unitFilters?: UnitFilters;
  isLoadingFilters: boolean;
  showResale?: boolean;
  priceSlider: PriceSlider;
  setPriceValue: (price: PriceValue) => void;
  isLoadingCurrency?: boolean;
  showSortBy?: boolean;
  isCreateSale?: boolean;
  activeCountry?: string;
}

export function UnitsFilters({
  showResale = true,
  unitFilters,
  isLoadingFilters,
  priceSlider,
  isLoadingCurrency = false,
  setPriceValue,
  showSortBy,
  portal,
  isCreateSale,
}: Readonly<UnitsFiltersProps>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  //filter tags start
  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const removeFilterTab = (key: string) => {
    const newParams = new URLSearchParams(searchParams);
    const keyCleansed = key.trim().toLowerCase().split(' ').join('_'); // to remove any possible white spaces
    if (key.toLowerCase() === 'price') {
      newParams.delete('min_price');
      newParams.delete('max_price');
      newParams.delete('currency');
    }
    newParams.delete(keyCleansed);
    setSearchParams(newParams, { replace: true });
  };

  const minPrice = searchParams.get('min_price');
  const maxPrice = searchParams.get('max_price');
  const currency = searchParams.get('currency');

  const priceFilter =
    minPrice && maxPrice && currency
      ? `Price: ${Number(minPrice).toLocaleString()} - ${Number(
          maxPrice
        ).toLocaleString()} ${currency}`
      : null;
  const chosenFilter = isCreateSale
    ? []
    : Array.from(searchParams.entries())
        .filter(
          ([key]) =>
            !['on_resale', 'min_price', 'max_price', 'currency'].includes(key)
        )
        .map(([key, value]) => {
          const label = capitalize(key).split('_').join(' ');
          const values = value.split(',');

          // Match filter data if available
          const filterArray = unitFilters?.[key as keyof typeof unitFilters] as
            | { id?: number; slug?: string; name: string }[]
            | undefined;

          // Special case: unit_types (match by ID)
          if (key === 'unit_types' && filterArray) {
            const matchedNames = values
              .map(
                (id) =>
                  filterArray?.find((item) => String(item.id) === id)?.name ??
                  id
              )
              .join(' & ');
            return `${label} : ${matchedNames}`;
          }

          // Special case: unit_types (match by ID)
          if (
            (key === 'bedrooms' ||
              key === 'bathrooms' ||
              key === 'built_up_areas') &&
            filterArray
          ) {
            const matchedNames = values.map((id) => id).join(' & ');

            if (key === 'bedrooms' || key === 'bathrooms') {
              return `${label} : ${matchedNames}`;
            }

            if (key === 'built_up_areas') {
              return `${label} : ${matchedNames} m²`;
            }
          }

          // Match slugs for filters with slug-name structure
          if (filterArray) {
            const matchedNames = values
              .map(
                (slug) =>
                  filterArray.find((item) => item.slug === slug)?.name ??
                  capitalize(slug)
              )
              .join(' & ');
            return `${label} : ${matchedNames}`;
          }

          if (key === 'sort_by') {
            const sortValue =
              SortByOptions.find((opt) => opt.id === Number(value))?.name ?? '';
            return `${label} : ${sortValue}`;
          }

          return `${label} : ${values.map(capitalize).join(' & ')}`;
        });

  if (priceFilter) {
    chosenFilter.push(priceFilter);
  }
  //filter tags end

  const onShowFiltersModal = () => {
    setShowFiltersModal(true);
    document.body.style.overflow = 'hidden';
  };

  const onCloseModal = () => {
    setShowFiltersModal(false);
    document.body.style.overflow = '';
  };

  const onPriceChangeEnd = (price: PriceValue) => {
    setSearchParams(
      (searchParams) => {
        searchParams.set(FiltersEnums.MIN_PRICE, price.min.toString());
        searchParams.set(FiltersEnums.MAX_PRICE, price.max.toString());
        searchParams.set(FiltersEnums.CURRENCY, price.currency);
        return searchParams;
      },
      { replace: true }
    );
  };

  const onChange = (value: string) => {
    if (value) {
      setSearchParams({ [FiltersEnums.UNIT_NAME]: value }, { replace: true });
    } else {
      setSearchParams(
        (searchParam) => {
          searchParam.delete(FiltersEnums.UNIT_NAME);
          return searchParam;
        },
        {
          replace: true,
        }
      );
    }
  };
  const debouncedOnChange = debounce(onChange, 300);
  return (
    <div className={styles['wrapper']}>
      {portal === 'shopper' ? (
        <p className={styles['filter-header']}>{t('filterBy')}</p>
      ) : (
        <div className={styles['search-input']}>
          <img src={searchIcon} alt="" role="presentation" />
          <input
            type="text"
            defaultValue={searchParams.get(FiltersEnums.UNIT_NAME) || ''}
            placeholder={t('searchUnitById')}
            onChange={(e) => debouncedOnChange(e.target.value)}
          />
        </div>
      )}
      <div className={`${styles['dropdowns']} ${styles['dropdowns-layout']}`}>
        {portal === 'shopper' ? (
          <div>
            <p className={styles['filter-label']}>{t('country')}</p>
            <MultiFilterDropdown
              isLoading={isLoadingFilters}
              filterOptions={convertToOptionsWithSlug(unitFilters?.countries)}
              filterKey="countries"
              showLabel={false}
              placeholder="Country"
              containerClassName="dropdown-container"
            />
          </div>
        ) : null}

        <div>
          <p className={styles['filter-label']}>{t('destination')}</p>
          <MultiFilterDropdown
            isLoading={isLoadingFilters}
            filterOptions={convertToOptionsWithSlug(unitFilters?.destinations)}
            filterKey="destinations"
            showLabel={false}
            placeholder="Destination"
            containerClassName="dropdown-container"
          />
        </div>

        {portal === 'shopper' ? (
          <div>
            <p className={styles['filter-label']}>{t('bedrooms')}</p>
            <MultiFilterDropdown
              isLoading={isLoadingFilters}
              filterOptions={convertNumFiltersToOptions(unitFilters?.bedrooms)}
              filterKey="bedrooms"
              labelTranslationKey="noOfBedrooms"
              containerClassName=""
              placeholder="No of bedrooms"
              showLabel={false}
            />
          </div>
        ) : (
          <>
            <div>
              <p className={styles['filter-label']}>{t('unitType')}</p>
              <MultiFilterDropdown
                isLoading={isLoadingFilters}
                filterOptions={unitFilters?.unit_types}
                filterKey="unit_types"
                labelTranslationKey="unitType"
                showLabel={false}
                placeholder="Unit Type"
                containerClassName="dropdown-container"
              />
            </div>
            <div>
              <p className={styles['filter-label']}>{t('priceRange')}</p>
              <MinMaxDropdown
                onPriceChangeEnd={onPriceChangeEnd}
                priceSlider={priceSlider}
                setPriceValue={setPriceValue}
                disabled={isLoadingCurrency || isLoadingFilters}
                onChange={(val) => {
                  setPriceValue({ ...priceSlider.price, ...val });
                }}
                onChangeEnd={(val) => onPriceChangeEnd(val)}
                customMargin={0}
              />
            </div>
          </>
        )}

        {showResale && portal === 'shopper' && (
          <OnResaleButton
            inlineStyle={{
              display: 'flex',
              alignItems: 'center',
              height: '46px',
              gap: '12px',
            }}
            wrapperClassName={styles['mt-on-bg-screen']}
            disabled={isLoadingFilters}
          />
        )}

        {portal === 'shopper' ? (
          <NavyButton
            className={styles['more-filters']}
            onClick={onShowFiltersModal}
          >
            {t('moreFilters')}
          </NavyButton>
        ) : (
          <button
            className={styles['more-filters-broker']}
            onClick={onShowFiltersModal}
          >
            {t('moreFilters')}
          </button>
        )}

        {showSortBy && (
          <SingleFilterDropdown
            wrapperClassName={styles['sort-filter']}
            isClearable
            filterOptions={SortByOptions}
            filterKey="sort_by"
            labelTranslationKey="sortBy"
          />
        )}
      </div>

      {chosenFilter.length > 0 && portal === 'shopper' && (
        <div className={styles['filter-tabs-wrapper']}>
          <div className={styles['filter-tabs-container']}>
            {chosenFilter.map((item, index) => {
              const [key] = item.split(':');
              return (
                <p key={index} className={styles['filter-tabs']}>
                  {item}

                  <button onClick={() => removeFilterTab(key)}>
                    <img
                      src={CloseIcon}
                      alt=""
                      className={styles['filter-close']}
                      role="presentation"
                    />
                  </button>
                </p>
              );
            })}
          </div>
          <button
            className={styles['modal-clear']}
            style={{ whiteSpace: 'nowrap' }}
            onClick={() => handleClearAllFilters(searchParams, setSearchParams)}
          >
            {t('clearFilters')}
          </button>
        </div>
      )}

      <Modal
        title={t('allFilters')}
        sideModal
        sideModalWidth
        show={showFiltersModal}
        onClose={onCloseModal}
      >
        <div className={styles['wrapper']}>
          <button
            className={styles['modal-clear']}
            onClick={() => handleClearAllFilters(searchParams, setSearchParams)}
          >
            {t('clearFilters')}
          </button>
          <div
            className={`${styles['dropdowns']} ${styles['filters-wrapper']} ${styles['modal-filters']}`}
          >
            {portal === 'shopper' && (
              <div>
                <p className={styles['filter-label']}>{t('destination')}</p>
                <MultiFilterDropdown
                  isLoading={isLoadingFilters}
                  filterOptions={convertToOptionsWithSlug(
                    unitFilters?.destinations
                  )}
                  filterKey="destinations"
                  showLabel={false}
                  placeholder="Destination"
                  containerClassName=""
                  labelTranslationKey=""
                />
              </div>
            )}
            <div>
              <p className={styles['filter-label']}>{t('neighborhoods')}</p>
              <MultiFilterDropdown
                isLoading={isLoadingFilters}
                filterOptions={convertToOptionsWithSlug(unitFilters?.projects)}
                filterKey="projects"
                labelTranslationKey="neighborhoods"
                showLabel={false}
                placeholder="Neighborhoods"
                containerClassName=""
              />
            </div>
            <div>
              <p className={styles['filter-label']}>{t('phases')}</p>
              <MultiFilterDropdown
                isLoading={isLoadingFilters}
                filterOptions={convertToOptionsWithSlug(
                  unitFilters?.project_phases
                )}
                filterKey="project_phases"
                labelTranslationKey="phases"
                showLabel={false}
                placeholder="Phases"
                containerClassName=""
              />
            </div>
            <div>
              <p className={styles['filter-label']}>{t('unitSize')}</p>
              <MultiFilterDropdown
                isLoading={isLoadingFilters}
                filterOptions={convertNumFiltersToOptions(
                  unitFilters?.built_up_areas
                )}
                filterKey="built_up_areas"
                labelTranslationKey="unitSize"
                getOptionLabel={(v) => `${v.name} ㎡`}
                showLabel={false}
                placeholder="Unit Size"
                containerClassName=""
              />
            </div>
            {portal === 'shopper' && (
              <div>
                <p className={styles['filter-label']}>{t('unitType')}</p>
                <MultiFilterDropdown
                  isLoading={isLoadingFilters}
                  filterOptions={unitFilters?.unit_types}
                  filterKey="unit_types"
                  labelTranslationKey="unitType"
                  showLabel={false}
                  placeholder="Unit Type"
                  containerClassName="dropdown-container"
                />
              </div>
            )}
            {portal !== 'shopper' && (
              <div>
                <p className={styles['filter-label']}>{t('noOfBedrooms')}</p>
                <MultiFilterDropdown
                  isLoading={isLoadingFilters}
                  filterOptions={convertNumFiltersToOptions(
                    unitFilters?.bedrooms
                  )}
                  filterKey="bedrooms"
                  labelTranslationKey="noOfBedrooms"
                  containerClassName=""
                  placeholder="No of bedrooms"
                  showLabel={false}
                />
              </div>
            )}
            <div>
              <p className={styles['filter-label']}>{t('noOfBathrooms')}</p>
              <MultiFilterDropdown
                isLoading={isLoadingFilters}
                filterOptions={convertNumFiltersToOptions(
                  unitFilters?.bathrooms
                )}
                filterKey="bathrooms"
                labelTranslationKey="noOfBathrooms"
                containerClassName=""
                placeholder="No of bathrooms"
                showLabel={false}
              />
            </div>
            <PriceRangeSlider
              disabled={isLoadingCurrency || isLoadingFilters}
              priceSlider={priceSlider}
              onChange={(val) => {
                setPriceValue({ ...priceSlider.price, ...val });
              }}
              onChangeEnd={(val) => onPriceChangeEnd(val)}
              customMargin={0}
            />
            <div>
              <p className={styles['filter-label']}>{t('finishingStatus')}</p>
              <MultiFilterDropdown
                isLoading={isLoadingFilters}
                filterOptions={convertStringFiltersToOptions(
                  unitFilters?.finishing_status
                )}
                filterKey="finishing_status"
                labelTranslationKey="finishingStatus"
                containerClassName=""
                placeholder="Finishing status"
                showLabel={false}
              />
            </div>
            {showResale && (
              <OnResaleButton
                inlineStyle={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '46px',
                  gap: '12px',
                }}
                wrapperClassName={styles['mt-on-bg-screen']}
                disabled={isLoadingFilters}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

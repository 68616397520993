import {
  FiltersEnums,
  SortEnums,
  SortValuesEnums,
} from '@orascom/api-interfaces';

const singleValueKeys = [
  FiltersEnums.ON_RESALE,
  FiltersEnums.UNIT_NAME,
  FiltersEnums.EXPLORE,
];

// these values need a custom logic
const customValueKeys = [
  FiltersEnums.MIN_PRICE,
  FiltersEnums.MAX_PRICE,
  FiltersEnums.CURRENCY,
  FiltersEnums.SORT_BY,
];

interface FilterQueryProps {
  searchParams: URLSearchParams;
  ignoreKeys?: string[];
}
export const getFiltersSearchParams = ({
  searchParams,
  ignoreKeys,
}: FilterQueryProps) => {
  const filterParams: Record<string, string> = {};

  searchParams.forEach((values, key) => {
    if (ignoreKeys?.includes(key)) return;

    // custom values are handled in a different way
    if (customValueKeys.includes(key as FiltersEnums)) {
      if (key === FiltersEnums.SORT_BY) {
        switch (Number(values)) {
          case SortValuesEnums.HIGHEST_PRICE:
            filterParams[key] = SortEnums.PRICE;
            filterParams['direction'] = SortEnums.DESCENDING;
            break;

          case SortValuesEnums.LOWEST_PRICE:
            filterParams[key] = SortEnums.PRICE;
            filterParams['direction'] = SortEnums.ASCENDING;
            break;

          case SortValuesEnums.NEWEST:
            filterParams[key] = SortEnums.DATE;
            filterParams['direction'] = SortEnums.DESCENDING;
            break;

          default:
            break;
        }
      }

      if (key === FiltersEnums.MIN_PRICE) {
        const min_price = searchParams.get(FiltersEnums.MIN_PRICE);
        const max_price = searchParams.get(FiltersEnums.MAX_PRICE);
        const currency = searchParams.get(FiltersEnums.CURRENCY);

        if (min_price && max_price && currency) {
          filterParams[FiltersEnums.MIN_PRICE] = min_price;
          filterParams[FiltersEnums.MAX_PRICE] = max_price;
        }
      }
      return;
    }

    // normal values
    if (singleValueKeys.includes(key as FiltersEnums)) {
      filterParams[key] = values;
    } else {
      values.split(',').forEach((value, ind) => {
        filterParams[`${key}[${ind}]`] = value;
      });
    }
  });

  const filterSearchParams = new URLSearchParams(filterParams);

  return filterSearchParams;
};

export default getFiltersSearchParams;

/* eslint-disable @nx/enforce-module-boundaries */
import styles from './request-addon-form.module.scss';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  GoldButton,
  Loader,
  SelectDropdown,
  Spinner,
} from '@orascom/common-components';
import successIcon from '../../../assets/icons/success.svg';
import errorSubmitIcon from '../../../assets/icons/error-2.svg';
import { PreDeliveryUser as PreDeliveryUserUtilities } from '../../../utils/pre-delivery/user.utils';
import { OptionValue, ServiceRequestTypeEnum } from '@orascom/api-interfaces';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '../../../assets/icons/error.svg';
import { useContext, useEffect, useState } from 'react';
import { ServiceRequestFormData } from '../../../definitions/interfaces/service-requests.interface';
import { USER_CONTEXT } from '../../../contexts/user-context';
import { getUserSaleByUnitId } from '../../../utils/authentication.utils';
import { ContactTypeEnum } from '../../../definitions/interfaces/contact.interface';
import { useGetMyUnits } from '../../../pages/pre-delivery/hooks/use-get-my-units';

/* eslint-disable-next-line */
export interface RequestAddonFormProps {
  addonId?: number;
  unitId: number;
  addonName: string;
}
interface FormInitialData {
  contactType: OptionValue | null;
  message: string;
}

export interface RequestAddonFormData {
  addon_id: number;
  communication_channel: string;
  message: string;
}

export function RequestAddonForm(props: Readonly<RequestAddonFormProps>) {
  const { t } = useTranslation();
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const userContext = useContext(USER_CONTEXT);
  const [customerId, setCustomerId] = useState<string>('');
  const [myUnits, loadingMyUnits] = useGetMyUnits();

  const getCustomerId = (selectedUnitId: number) => {
    if (userContext?.userReservationDetails && selectedUnitId) {
      const userSale = getUserSaleByUnitId(
        userContext?.userReservationDetails,
        selectedUnitId.toString()
      );
      if (userSale) {
        setCustomerId(userSale.customer_id);
      }
    }
  };

  useEffect(() => {
    if (myUnits.length) {
      getCustomerId(myUnits[0].id);
    }
  }, [myUnits]);

  const handleSubmitForm = (
    formData: FormInitialData,
    resetForm: () => void
  ) => {
    if (userContext.user && formData.contactType) {
      const mappedData: ServiceRequestFormData = {
        customer_source_id: customerId,
        preferred_way_of_contact: Number(formData.contactType.value),
        preferred_time_of_contact: 1,
        request_details: `requested addon: ${props.addonName}, message: ${formData.message}`,
        service_slug: ServiceRequestTypeEnum.ADDON_INTEREST,
      };
      PreDeliveryUserUtilities.submitServiceRequest(
        props.unitId.toString(),
        mappedData
      )
        .then(() => {
          setSuccessMsg(true);
        })
        .catch(() => setErrorMsg(true))
        .finally(() => resetForm());
    }
  };
  const RequestAddonSchema = Yup.object().shape({
    contactType: Yup.object().nullable().required(t('fieldIsRequired')),
    message: Yup.string().max(250, 'maximumCharacters250'),
  });

  if (errorMsg) {
    return (
      <div>
        <div className={styles['error']}>
          <p className={styles['error-title']}>
            <img src={errorSubmitIcon} role="presentation" alt="" />
            <span>{t('formSubmitErrorTitle')}</span>
          </p>
          <p className={styles['error-message']}>
            {t('formSubmitErrorMessage')}
          </p>
        </div>
        <GoldButton onClick={() => setErrorMsg(false)}>
          {t('Try again')}
        </GoldButton>
      </div>
    );
  }
  if (successMsg) {
    return (
      <div>
        <div className={styles['success']}>
          <p className={styles['success-title']}>
            <img src={successIcon} role="presentation" alt="" />
            <span>{t('formSubmitSuccessTitle')}</span>
          </p>
          <p className={styles['success-message']}>
            {t('formSubmitSuccessMessage')}
          </p>
        </div>
        <GoldButton onClick={() => setSuccessMsg(false)}>
          {t('Submit another request')}
        </GoldButton>
      </div>
    );
  }

  if (loadingMyUnits) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={{
        contactType: null,
        message: '',
      }}
      validationSchema={RequestAddonSchema}
      onSubmit={(values, { resetForm }) => {
        handleSubmitForm(values, resetForm);
      }}
    >
      {({ errors, touched, setFieldValue, values, isSubmitting }) =>
        !isSubmitting ? (
          <Form>
            <div className="input-wrapper">
              <label htmlFor="contactType">{t('preferredWayOfContact')}*</label>
              <SelectDropdown
                name="contactType"
                className={`${styles['select']} ${
                  errors.contactType && touched.contactType ? 'error' : ''
                }`}
                placeholder={t('selectPlaceholder')}
                onChange={(option) => setFieldValue('contactType', option)}
                options={[
                  { value: ContactTypeEnum.MOBILE, label: 'Phone call' },
                  { value: ContactTypeEnum.WHATS_APP, label: 'Whatsapp' },
                  { value: ContactTypeEnum.EMAIL, label: 'Email' },
                  {
                    value: ContactTypeEnum.VIRTUAL_MEETING,
                    label: 'virtual meeting',
                  },
                ]}
                selectedOption={values.contactType ?? null}
              />
              {errors.contactType && touched.contactType ? (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {errors.contactType}
                </p>
              ) : null}
            </div>

            <div className="input-wrapper">
              <label htmlFor="requestDetails">{`${t('message')} ${t(
                'optional'
              )}`}</label>
              <Field
                name="message"
                as="textarea"
                rows="6"
                className={errors.message && touched.message ? 'error' : ''}
                placeholder={t('messagePlaceHolder')}
              />
              {errors.message && touched.message ? (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {errors.message}
                </p>
              ) : null}
            </div>
            <GoldButton type="submit">{t('submitRequest')}</GoldButton>
          </Form>
        ) : (
          <div className={styles['submitting']}>
            <Spinner />
            <p>{t('sendingRequest')}</p>
          </div>
        )
      }
    </Formik>
  );
}

export default RequestAddonForm;

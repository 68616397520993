import { ReservationStateEnum } from '@orascom/api-interfaces';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './reservation-breadcrumbs.module.scss';
import { RESERVATION_CONTEXT } from '@orascom/common-components';

type StylesOptions = 'inactive' | 'active' | 'done';

interface BreadCrumbsStylesInterface {
  payment_plan: StylesOptions;
  pay_reservation: StylesOptions;
  your_info: StylesOptions;
  reservation_form: StylesOptions;
}

export function ReservationBreadcrumbs() {
  const [breadCrumbsStyles, setBreadCrumbsStyles] =
    useState<BreadCrumbsStylesInterface>();

  const { t } = useTranslation();
  const { reservationState } = useContext(RESERVATION_CONTEXT);

  useEffect(() => {
    switch (reservationState) {
      case ReservationStateEnum.PAYMENT_PLAN:
        setBreadCrumbsStyles({
          payment_plan: 'active',
          pay_reservation: 'inactive',
          your_info: 'inactive',
          reservation_form: 'inactive',
        });
        break;

      case ReservationStateEnum.PAY_RESERVATION_FEE:
        setBreadCrumbsStyles({
          payment_plan: 'done',
          pay_reservation: 'active',
          your_info: 'inactive',
          reservation_form: 'inactive',
        });
        break;

      case ReservationStateEnum.PAY_RESERVATION_FEE_DONE:
        setBreadCrumbsStyles({
          payment_plan: 'done',
          pay_reservation: 'done',
          your_info: 'inactive',
          reservation_form: 'inactive',
        });
        break;

      case ReservationStateEnum.YOUR_INFO:
        setBreadCrumbsStyles({
          payment_plan: 'done',
          pay_reservation: 'done',
          your_info: 'active',
          reservation_form: 'inactive',
        });
        break;

      case ReservationStateEnum.RESERVATION_FORM:
        setBreadCrumbsStyles({
          payment_plan: 'done',
          pay_reservation: 'done',
          your_info: 'done',
          reservation_form: 'active',
        });
        break;

      case ReservationStateEnum.RESERVATION_FORM_DONE:
        setBreadCrumbsStyles({
          payment_plan: 'done',
          pay_reservation: 'done',
          your_info: 'done',
          reservation_form: 'done',
        });
        break;

      default:
        break;
    }
  }, [reservationState]);

  return (
    <div className={styles['wrapper']}>
      <div
        className={`${styles['breadcrumb']} ${
          styles[`breadcrumb--${breadCrumbsStyles?.payment_plan}`]
        }`}
      >
        <div className={styles['icon']} />
        <p>{t('paymentPlan')}</p>
      </div>

      <div
        className={`${styles['breadcrumb']} ${
          styles[`breadcrumb--${breadCrumbsStyles?.pay_reservation}`]
        }`}
      >
        <div className={styles['icon']} />
        <p>{t('payReservationFee')}</p>
      </div>

      <div
        className={`${styles['breadcrumb']} ${
          styles[`breadcrumb--${breadCrumbsStyles?.your_info}`]
        }`}
      >
        <div className={styles['icon']} />
        <p>{t('yourInformation')}</p>
      </div>

      <div
        className={`${styles['breadcrumb']} ${
          styles[`breadcrumb--${breadCrumbsStyles?.reservation_form}`]
        }`}
      >
        <div className={styles['icon']} />
        <p>{t('insertSignature')}</p>
      </div>
    </div>
  );
}

export default ReservationBreadcrumbs;

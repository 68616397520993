import { useEffect, useRef, useState } from 'react';
import { PriceSlider, PriceValue } from '@orascom/api-interfaces';
import { PriceRangeSlider } from '@orascom/common-components';
import './filter-price-dropdown.scss';

interface MinMaxDropdownProps {
  priceSlider: PriceSlider;
  setPriceValue: (arg: PriceValue) => void;
  disabled?: boolean;
  className?: string;
  keyValue?: number | string;
  onChange: (arg: PriceValue) => void;
  onChangeEnd?: (arg: PriceValue) => void;
  horizontalLine?: boolean;
  customMargin?: number;
  onPriceChangeEnd: (price: PriceValue) => void;
}

export function MinMaxDropdown(props: Readonly<MinMaxDropdownProps>) {
  const [isOpen, setIsOpen] = useState(false);
  const {
    priceSlider,
    setPriceValue,
    disabled,
    className,
    keyValue,
    onPriceChangeEnd,
  } = props;

  const dropdownRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const closeDropdown = (event: MouseEvent) => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', closeDropdown);
    return () => {
      document.removeEventListener('mousedown', closeDropdown);
    };
  }, [dropdownRef, isOpen]);

  const toggleIsOpen = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <div key={keyValue} className="search-bar">
      <button className="minmax" onClick={toggleIsOpen} ref={dropdownRef}>
        <span className="minmax__label">
          {priceSlider.maxRange && priceSlider.minRange
            ? priceSlider.price.min + ' '+
              priceSlider.price.currency +
              ' - ' +
              priceSlider.price.max + ' '+
              priceSlider.price.currency
            : 'Price range'}
        </span>
        <p>
          <i className="minmax__arrow"></i>
        </p>
        {isOpen && (
          <button
            className="minmax__dropdown"
            onClick={(e) => e.stopPropagation()}
          >
            <PriceRangeSlider
              disabled={disabled}
              priceSlider={priceSlider}
              onChange={(val) => {
                setPriceValue({ ...priceSlider.price, ...val });
              }}
              onChangeEnd={(val) => onPriceChangeEnd(val)}
              customMargin={0}
            />
          </button>
        )}
      </button>
    </div>
  );
}

import { AboutInfo, Common, FilterValue } from './common.interface';
import { CountryInfo } from './country.interface';
import { Destination } from './destination.interface';
import { Phase, UnitInterface, UnitProject, UnitType } from './unit.interface';

export interface UnitConstructionUpdate extends Common {
  title: string;
  image: string;
  date: number;
}

interface UnitAddOn extends Partial<UnitConstructionUpdate> {
  price: number;
  name: string;
  title?: string;
  description?: string;
  gallery?: string[];
  available_for_purchase?: boolean;
  is_purchasable?: boolean;
  unit?: UnitInterface;
  metadata?: {
    specs: AboutInfo[];
  };
}

export interface PaymentProgress {
  down_payment_paid: number;
  total_installments_paid: number;
  total_unpaid: number;
}

export interface UnitAccessCard extends Common {
  image: string;
}

export interface User {
  id: number;
  name: string;
  email: string;
  phone: string;
  parsed_phone: {
    country_code: string;
    national_phone: string;
  };
}
export interface UserResponse {
  token: string;
  token_type: string;
  user: User;
}

export enum UserReservationSaleStatusEnum {
  DRAFT = 'draft',
  PENDING_INFO = 'pending_info',
  DOWNLOAD_FORM = 'download_form',
  CANCELED = 'canceled',
  DONE = 'done',
}

// TODO: remove this
export interface AvailableFilters {
  countries: FilterValue[];
  destinations: FilterValue[];
  projects: FilterValue[];
  unit_types: FilterValue[];
  unit_sizes: number[];
  built_up_areas: number[];
  bedrooms: number[];
  bathrooms: number[];
  min_dollar_price: number;
  max_dollar_price: number;
  delivery_dates: number[];
  finishing_status: string[];
  show_resale: boolean;
  tags: FilterValue[];
  sort_by: { status: string }[];
}

type numOrNull = number | null;
type stringOrNull = string | null;
export interface UnitFilters {
  bedrooms: numOrNull[];
  bathrooms: numOrNull[];
  built_up_areas: numOrNull[];
  countries: Pick<CountryInfo, 'id' | 'name' | 'slug'>[];
  destinations: Destination[];
  projects: Omit<UnitProject, 'destination'>[];
  unit_types: Omit<UnitType, 'image'>[];
  min_dollar_price: string;
  max_dollar_price: string;
  finishing_status: stringOrNull[];
  tags: numOrNull[];
  delivery_dates: numOrNull[];
  project_phases: Phase[];
}

export interface PaginationInterface {
  current_page: number;
  last_page: number;
  per_page: number;
  count: number;
  total: number;
}

export interface UserReservationDetails {
  token: string;
  token_type: string;
  user: User;
}
export interface AvailableFiltersPayload<D extends unknown = unknown> {
  units: D;
  available_filter: AvailableFilters;
}

export interface PaginationPayload<D extends unknown = unknown> {
  data: D;
  meta: PaginationInterface;
}

export interface FilterParams {
  from: string;
  to: string;
  page?: string;
  per_page?: string;
  sort_by?: string;
  sort_dir?: string;
  'destination_ids[]'?: string[];
  'unit_type_ids[]'?: string[];
  'broker_ids[]'?: string[];
  'brokerage_company_ids[]'?: string[];
  'status[]'?: string[];
  'sales_managers_titles[]'?: string[];
  request_type?: string;
}

/**
 * Error type returned from the API
 *
 * @export
 */
export type APIError = Record<string, string[]>;

/**
 * payload for response from the API.
 *
 * @export
 */
export interface Payload<D = unknown> {
  /**
   * Message sent with the response.
   *
   * @type {string}
   * @memberof Payload
   */
  message: string;

  /**
   * data returned with the payload.
   *
   * @type {D}
   * @memberof Payload
   */
  data: D;

  /**
   * set of errors returned in the payload
   *
   * @type {APIError}
   * @memberof Payload
   */
  errors: APIError;
}

/* eslint-disable import/no-extraneous-dependencies */
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import leftArrowIcon from '../../../assets/icons/left-arrow.svg';
import rightArrowIcon from '../../../assets/icons/right-arrow.svg';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import styles from './cards-slider.module.scss';
import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';

/* eslint-disable-next-line */
export interface CardsSliderProps {
  children: React.ReactNode[];
  slidesPerView?: number;
  slidesPerViewMobile?: number;
  spaceBetween?: number;
  className?: string;
}

export function CardsSlider(props: Readonly<CardsSliderProps>) {
  const language = i18next.language;

  return (
    <div
      className={`${styles['wrapper']} card-slider ${
        props.className ? styles[props.className] : ''
      }`}
    >
      <Swiper
        modules={[Pagination, Navigation]}
        spaceBetween={24}
        rewind={true}
        breakpoints={{
          320: {
            slidesPerView: props.slidesPerViewMobile ?? 1.1,
          },
          768: {
            slidesPerView: props.slidesPerView ?? 'auto',
          },
        }}
        pagination={{
          el: '.cards-slider__pagination',
          dynamicBullets: false,
        }}
        navigation={{
          prevEl: '.cards-slider__prev',
          nextEl: '.cards-slider__next',
        }}
        touchStartPreventDefault={false}
        dir={language === 'ar' ? 'rtl' : 'ltr'}
        key={language}
      >
        {props.children?.map((child) => (
          <SwiperSlide key={uuidv4()}>{child}</SwiperSlide>
        ))}
        <div className="cards-slider__navigation">
          <button
            type="button"
            className="cards-slider__prev"
            aria-label="previous"
          >
            <img src={leftArrowIcon} alt="" />
          </button>
          <div className="cards-slider__pagination"></div>
          <button
            type="button"
            className="cards-slider__next"
            aria-label="next"
          >
            <img src={rightArrowIcon} alt="" />
          </button>
        </div>
      </Swiper>
    </div>
  );
}

export default CardsSlider;

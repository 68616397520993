import { UnitDetails } from './unit.interface';

export interface Common {
  id: number;
}

export interface FilterValue extends Common {
  name: string;
  country_name?: string;
}

export interface OptionValue<T = string | number> {
  label: string;
  value: T;
}

export type FilterOption = { id: number | string; name: string };

export interface SectionTag {
  id: number;
  name: string;
}

export enum SortEnums {
  DATE = 'created_at',
  PRICE = 'dollar_price',
  DESCENDING = 'desc',
  ASCENDING = 'asc',
}

export enum SortValuesEnums {
  HIGHEST_PRICE = 0,
  LOWEST_PRICE = 1,
  NEWEST = 2,
}

export interface RangeInterface {
  min: number;
  max: number;
  currency?: string;
}

export interface AboutInfo {
  name: string;
  value: string;
  type: string;
  unit: string;
  slug: string;
  is_predefined: boolean;
}

export interface SectionInfo {
  title: string;
  tags: SectionTag[];
}

export enum FiltersEnums {
  COUNTRIES = 'countries',
  DESTINATIONS = 'destinations',
  PROJECTS = 'projects',
  UNIT_TYPES = 'unit_types',
  UNIT_SIZES = 'built_up_areas',
  BEDROOMS = 'bedrooms',
  BATHROOMS = 'bathrooms',
  DELIVERY_DATES = 'delivery_dates',
  FINISHING_STATUS = 'finishing_status',
  ON_RESALE = 'on_resale',
  TAGS = 'tags',
  SORT_BY = 'sort_by',
  MIN_PRICE = 'min_price',
  MAX_PRICE = 'max_price',
  CURRENCY = 'currency',
  UNIT_NAME = 'name',
  UNIT_NUMBER = 'unit_number',
  EXPLORE = 'explore',
  PROJECT_PHASES = 'project_phases',
}

export const filterKeysArray = Object.values(FiltersEnums);

export interface FiltersObjectInterface {
  countries: string;
  destinations: string;
  projects: string;
  unit_types: string;
  unit_sizes: string;
  bedrooms: string;
  bathrooms: string;
  delivery_dates: string;
  finishing_status: string;
  on_resale: string;
  tags: string;
  price: string;
  sort_by: string;
  project_phases: string;
}

export enum UserRole {
  BROKER = 'broker',
  SALES_MANAGER = 'sales_manager',
  ALL = 'all',
}

export interface UserReservationSale {
  crm_status?: string | null;
  customer_id: string;
  destination: {
    brochure?: string;
    id: number;
    name: string;
    slug: string;
    welcome_description?: string;
  };
  id: number;
  payment_method?: string;
  paymentplan_source_id: string;
  portal_sale: boolean;
  reservation_amount: number;
  sale_id: string;
  sale_source_id: string;
  status: string;
  unit: UnitDetails;
  unit_id: number;
}

export type PeriodType =
  | 'yesterday'
  | 'today'
  | 'lastmonth'
  | 'lastyear'
  | 'last7days'
  | 'last30days'
  | 'last90days'
  | 'last6months';

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export enum CURRENCIES_ENUM {
  EGP = 'EGP',
  USD = 'USD',
  EUR = 'EUR',
  OMR = 'OMR',
  AED = 'AED',
}

export enum ServiceRequestTypeEnum {
  BOUNCED_PAYMENT = 'CR_BouncedPayment',
  CANCELLATION_REQUEST = 'CR_Cancellation',
  CHANGE_CONTACT_DETAILS = 'CR_ChangeContactDetails',
  CHANGE_OF_OWNERSHIP = 'CR_ChangeOfOwnership',
  CLIENT_RELATIONS_HANDOVER = 'CR_HandoverReq',
  COMPLAINTS = 'CR_Complaint',
  FINANCIAL_CLEARANCE = 'CR_FinancialClearance',
  FINANCIAL_REQUEST = 'CR_FinancialReq',
  SITE_VISITS = 'CR_SiteVisit',
  UNIT_MODIFICATION = 'CR_ModificationReq',
  GENERAL = 'CR_General',
  ADDON_INTEREST = 'CR_AddonInterest',
  SIGN_CONTRACT = 'CR_SIGNCONTRACT',
  ACCOUNT_STATEMENT = 'CR_AccountStatement',
}

/* eslint-disable @nx/enforce-module-boundaries */
import styles from './reservation-information-form.module.scss';
import { useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import { GoldButton, NavyButton } from '../common/button/button';
import { useTranslation } from 'react-i18next';
import ReservationInfoFormFields from '../reservation-info-form-fields/reservation-info-form-fields';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { Loader, RESERVATION_CONTEXT } from '@orascom/common-components';
import { useReservationInformationFormSchema } from './use-reservation-information-form-schema';
import { useReservationInformationPrerequisites } from './use-reservation-information-prerequisites';
import { useUpdateUserInformation } from './use-update-user-information';
import {
  MaritalStatusEnum,
  UpdateUserInfoFormData,
} from '@orascom/api-interfaces';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../api/routes';

export function ReservationInformationForm() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [ReservationInformationFormSchema] =
    useReservationInformationFormSchema();
  const [nationalities, countries, cities, occupations, connections, loading] =
    useReservationInformationPrerequisites();
  const [handleSubmitForm, loadingUpdateUserInfo] = useUpdateUserInformation();
  const reservationContext = useContext(RESERVATION_CONTEXT);
  const nationalitiesOptions = nationalities.map((nationalityOption) => {
    return {
      value: nationalityOption.nationality_id,
      label: nationalityOption.nationality_name,
    };
  });
  const countriesOptions = countries.map((countryOption) => {
    return {
      value: countryOption.country_id,
      label: countryOption.country_name,
    };
  });
  const citiesOptions = cities.map((cityOption) => {
    return {
      value: cityOption.city_id,
      label: cityOption.city_name,
    };
  });
  const occupationsOptions = occupations.map((occupationOption) => {
    return {
      value: occupationOption.code,
      label: occupationOption.label,
    };
  });

  const connectionsOptions = connections.map((connectionOption) => {
    return {
      value: connectionOption.connection_id,
      label: connectionOption.connection_name,
    };
  });

  if (loading || loadingUpdateUserInfo) {
    return <Loader />;
  }

  return (
    <div className={styles['reservation-info-form']}>
      <h1 className="reservation-form-title">{t('enterYourInformation')}</h1>
      <Formik
        initialValues={
          {
            nationality: null,
            country: null,
            city: null,
            citiesFound: Boolean(cities.length),
            cityName: '',
            district: '',
            address: '',
            occupation: null,
            occupationsFound: Boolean(occupations.length),
            occupationName: '',
            maritalStatus: MaritalStatusEnum.SINGLE,
            identificationForm:
              reservationContext.reservationUnitDetails?.project.destination
                .country.slug === 'montenegro'
                ? 'passport'
                : 'nationalId',
            nationalIdNumber: '',
            nationalIdFrontImage: undefined,
            nationalIdBackImage: undefined,
            passportNumber: '',
            passportImage: undefined,
            jointBuyer: false,
            jointBuyerFirstName: '',
            jointBuyerLastName: '',
            jointBuyerCountryCode: '20',
            jointBuyerPhoneNumber: '',
            jointBuyerEmail: '',
            jointBuyerNationality: null,
            jointBuyerCountry: null,
            jointBuyerCity: null,
            jointBuyerCityName: '',
            relationToBuyer: null,
            sameNationality: false,
            sameCountry: false,
            sameCity: false,
            sameDistrict: false,
            sameAddress: false,
            jointBuyerDistrict: '',
            jointBuyerAddress: '',
            jointBuyerOccupation: null,
            jointBuyerOccupationName: '',
            jointBuyerMaritalStatus: MaritalStatusEnum.SINGLE,
            jointBuyerIdentificationForm:
              reservationContext.reservationUnitDetails?.project.destination
                .country.slug === 'montenegro'
                ? 'passport'
                : 'nationalId',
            jointBuyerNationalIdNumber: '',
            jointBuyerNationalIdFrontImage: undefined,
            jointBuyerNationalIdBackImage: undefined,
            jointBuyerPassportNumber: '',
            jointBuyerPassportImage: undefined,
          } as UpdateUserInfoFormData
        }
        validationSchema={ReservationInformationFormSchema}
        onSubmit={handleSubmitForm}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form className="reservation-form">
            <ReservationInfoFormFields
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              values={values}
              countries={countriesOptions}
              nationalities={nationalitiesOptions}
              occupations={occupationsOptions}
              cities={citiesOptions}
              jointBuyer={false}
              connections={connectionsOptions}
            />

            <div className="input-wrapper checkbox-input-wrapper">
              <label>
                <Field type="checkbox" name="jointBuyer" />
                {t('jointBuyer')}
              </label>
            </div>
            {values.jointBuyer && (
              <div className="reservation-sub-form">
                <h2 className="reservation-form-title">
                  {t('jointBuyerInformation')}
                </h2>
                <ReservationInfoFormFields
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  values={values}
                  nationalities={nationalitiesOptions}
                  occupations={occupationsOptions}
                  countries={countriesOptions}
                  cities={citiesOptions}
                  jointBuyer={true}
                  connections={connectionsOptions}
                />
              </div>
            )}

            <div className="reservation-form-buttons">
              <GoldButton className={styles['contact-btn']} type="submit">
                {t('saveAndProceed')}
              </GoldButton>
              <NavyButton onClick={() => navigate(ROUTES.Home.path)}>
                {t('saveContinueLater')}
              </NavyButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ReservationInformationForm;

import { useContext, useEffect, useState } from 'react';
import { ROUTES } from '../../api/routes';
import { GoldButton } from '../common/button/button';
import calendarIcon from '../../assets/icons/calendar-reservation.svg';
import cashIcon from '../../assets/icons/cash-reservation.svg';
import userIcon from '../../assets/icons/user-reservation.svg';
import newsIcon from '../../assets/icons/news-reservation.svg';
import InfoIcon from '../../assets/icons/info.svg';
import {
  ReservationStateEnum,
  UnitReservationDetails,
} from '@orascom/api-interfaces';
import { useTranslation } from 'react-i18next';
import ReserveUnitCard from '../reserve-unit-card/reserve-unit-card';
import helpIcon from '../../assets/icons/help.svg';
import { USER_CONTEXT } from '../../contexts/user-context';
import { useLocation, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styles from './reservation-onboarding.module.scss';
import { Contact as ContactUtilities } from '../../utils/contact.utils';
import {
  CustomerInquiryEnum,
  PortalPageEnum,
  SubmitLeadData,
} from '../../definitions/interfaces/contact.interface';
import { toast } from 'react-toastify';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Loader, RESERVATION_CONTEXT } from '@orascom/common-components';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { getReservationSalesStatuses } from '../../utils/authentication.utils';
import { allowMultipleReservations } from '../../definitions/consts/envConstants';
import { analyticsContinueToReservationCustomEvent } from '../../utils/analytics-events';
import { CommonEventParameters, CurrencyContext } from '@orascom/utils';

export interface ReservationOnboardingProps {
  unit: UnitReservationDetails;
}

export function ReservationOnboarding(
  props: Readonly<ReservationOnboardingProps>
) {
  const [fromReferral, setFromReferral] = useState(false);
  const [referralNumber, setReferralNumber] = useState('');
  const [referralUnit, setReferralUnit] = useState('');
  const [referralUnitDestination, setReferralUnitDestination] = useState('');
  const [referralName, setReferralName] = useState('');
  const [loadingCreatingLead, setLoadingCreatingLead] = useState(false);
  const [countryCode, setCountryCode] = useState('20');

  const { t } = useTranslation();

  const userContext = useContext(USER_CONTEXT);
  const { setActiveCurrency } = useContext(CurrencyContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reservationContext = useContext(RESERVATION_CONTEXT);
  const navigate = useNavigate();

  const brokerId = queryParams.get('brokerId');

  const utmSource = localStorage.getItem('utm_source');
  const utmMedium = localStorage.getItem('utm_medium');
  const utmCampaign = localStorage.getItem('utm_campaign');

  const commonEventParams: CommonEventParameters = {
    userId: userContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Shopper',
    pageName: location.pathname,
  };

  useEffect(() => {
    if (!userContext.user) {
      navigate(`${ROUTES.Register.path}?redirect=${location.pathname}`);
    }
  }, []);

  const validatePhone = (phone: string, code: string) => {
    const phoneNumberWithCountryCode = `+${code}${parseInt(phone)}`;
    return isValidPhoneNumber(phoneNumberWithCountryCode);
  };

  const onContinueToReservation = () => {
    setActiveCurrency(props.unit.currency);
    const userSales = userContext.userReservationDetails;
    if (
      !allowMultipleReservations &&
      userSales &&
      getReservationSalesStatuses(userSales)?.length > 0
    ) {
      toast.error('please finish your ongoing reservation');
      return;
    }

    if (!userContext.user || !reservationContext.reservationUnitDetails) {
      toast.error('failed to load user data, please contact support');
      return;
    }

    if (fromReferral && !validatePhone(referralNumber, countryCode)) {
      toast.error('Please enter a valid phone number for referral');
      return;
    }
    let promise = Promise.resolve();
    if (
      !reservationContext.reservationIds?.customerId &&
      !reservationContext.reservationIds?.leadId
    ) {
      const firstName = userContext.user?.name?.split(' ')[0];
      const lastName =
        userContext.user?.name?.split(' ').slice(1).join(' ') || '-';
      const mappedData: SubmitLeadData = {
        first_name: firstName,
        last_name: lastName,
        email: userContext.user.email,
        phone:
          '+' +
          userContext.user.parsed_phone.country_code +
          userContext.user.parsed_phone.national_phone,
        mobile_country_code:
          userContext.user.parsed_phone.country_code.toString(),
        country:
          reservationContext.reservationUnitDetails?.project.destination.country
            .id,
        interested_in_unit_id:
          reservationContext.reservationUnitDetails?.id.toString(),

        destination_slug:
          reservationContext.reservationUnitDetails.project.destination.slug,
        preferred_communication_method: '2',

        preferred_communication_time: '1',
        portal_comments: '',
        is_predelivery: false,
        portal_page: PortalPageEnum.UNIT_PAGE,
        customer_inquiry: CustomerInquiryEnum.UNIT_PURCHASING,
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
      };

      if (fromReferral) {
        mappedData.referral_customer = `+${countryCode}${referralNumber}`;
        mappedData.referral_unit_name = referralUnit;
        mappedData.referral_unit_destination = referralUnitDestination;
        mappedData.referral_customer_name = referralName;
      }

      setLoadingCreatingLead(true);
      promise = ContactUtilities.submitShopperLead(mappedData)
        .then((res) => {
          reservationContext.setReservationIds?.({
            customerId: res.data.customer_source_id,
            leadId: res.data.lead_source_id,
          });
          setLoadingCreatingLead(false);
          analyticsContinueToReservationCustomEvent(commonEventParams);
          return Promise.resolve();
        })
        .catch((err) => {
          console.error(err);
          setLoadingCreatingLead(false);
          toast.error('failure to add lead Please contact support');
          return Promise.reject();
        });
    }

    promise
      .then(() => {
        if (fromReferral) {
          reservationContext.setReferral?.({
            mobileNumber: `+${countryCode}${referralNumber}`,
            unitName: referralUnit,
          });
        }
        reservationContext?.setReservationState?.(
          ReservationStateEnum.PAYMENT_PLAN
        );
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  if (loadingCreatingLead) {
    return <Loader />;
  }

  return (
    <div className={styles['wrapper']}>
      <div className={styles['unit-card-mobile']}>
        <ReserveUnitCard unit={props.unit} />
      </div>
      <div className={styles['steps']}>
        <div className={styles['step']}>
          <img src={calendarIcon} alt="" role="presentation" />
          <h6>{t('paymentPlan')}</h6>
          <p className={styles['details']}>
            {t('selectAndConfirmYourPaymentPlan')}
          </p>
        </div>
        <div className={styles['step']}>
          <img src={cashIcon} alt="" role="presentation" />
          <h6>{t('payReservationFee')}</h6>
          <p className={styles['details']}>
            {t('youNeedTPayYourReservationFees')}
          </p>
        </div>
        <div className={styles['step']}>
          <img src={userIcon} alt="" role="presentation" />
          <h6>{t('yourInformation')}</h6>
          <p className={styles['details']}>{t('fillOutInfo')}</p>
        </div>
        <div className={styles['step']}>
          <img src={newsIcon} alt="" role="presentation" />
          <h6>{t('reservationForm')}</h6>
          <p className={styles['details']}>
            {t('lastlyYouDownloadAndSignForm')}
          </p>
        </div>
      </div>
      {!userContext.user && (
        <p className={styles['note']}>{t('createAnAccountToGetStarted')}</p>
      )}
      {userContext.user && !brokerId && (
        <div className={styles['referral']}>
          <input
            type="checkbox"
            checked={fromReferral}
            readOnly
            name="referral-checkbox"
            id="referral-checkbox"
            onClick={() => setFromReferral((p) => !p)}
          />
          <label htmlFor="referral-checkbox">{t('referredByFriend')}</label>
          <img src={helpIcon} alt="" role="presentation" />
        </div>
      )}
      {fromReferral && (
        <>
          <div className={`${styles['referral-unit']} input-wrapper`}>
            <label htmlFor="number">{t('referralsName')}*</label>

            <input
              type="text"
              name="referralsName"
              id="referralsName"
              inputMode="text"
              placeholder={t('referralsNamePlaceHolder')}
              onInput={(e) => {
                setReferralName(e.currentTarget.value);
              }}
              className={styles['number-input']}
            />
          </div>
          <div className={`${styles['referral-number']} input-wrapper`}>
            <label htmlFor="number">{t('referralsPhoneNumber')}*</label>
            <div className={styles['inputs']}>
              <PhoneInput
                countryCodeEditable={false}
                inputProps={{
                  name: 'code',
                }}
                country={'eg'}
                onChange={(code) => {
                  setCountryCode(code);
                }}
                excludeCountries={['il']}
                enableSearch={true}
              />
              <input
                type="number"
                name="number"
                id="number"
                inputMode="numeric"
                placeholder="123 456 7890"
                onInput={(e) => {
                  setReferralNumber(e.currentTarget.value);
                }}
                className={styles['number-input']}
              />
            </div>
          </div>
          <div className={`${styles['referral-unit']} input-wrapper`}>
            <label htmlFor="unitDestination">
              {t('referralsUnitDestination')}*
            </label>

            <input
              type="text"
              name="unitDestination"
              id="unitDestination"
              inputMode="text"
              placeholder={t('referralsUnitDestinationPlaceHolder')}
              onInput={(e) => {
                setReferralUnitDestination(e.currentTarget.value);
              }}
              className={styles['number-input']}
            />
          </div>
          <div className={`${styles['referral-unit']} input-wrapper`}>
            <label htmlFor="number">{t('referralsUnit')}*</label>

            <input
              type="text"
              name="unit"
              id="unit"
              inputMode="numeric"
              placeholder={t('referralsUnitPlaceHolder')}
              onInput={(e) => {
                setReferralUnit(e.currentTarget.value);
              }}
              className={styles['number-input']}
            />
            <p className={`${styles['note']}`}>{t('referralsUnitNote')}</p>
          </div>
          <p className={styles['disclaimer']}>
            <img src={InfoIcon} role="presentation" alt="" />
            <span>{t('referralUnitDisclaimer')}</span>
          </p>
        </>
      )}
      {/* TODO: add it back after confirmation with design team */}
      {/* {!userContext.user && (
        <div className={styles['buttons']}>
          <GoldButton
            asLink
            to={`${ROUTES.Register.path}?redirect=${location.pathname}`}
          >
            {t('createAnAccount')}
          </GoldButton>
          <span>{t('or')}</span>
          <NavyButton
            asLink
            to={`${ROUTES.Login.path}?redirect=${location.pathname}`}
          >
            {t('login')}
          </NavyButton>
        </div>
      )} */}
      {userContext.user && (
        <div className={styles['buttons']}>
          <GoldButton
            onClick={onContinueToReservation}
            // TODO: add referralNumber Validation
            disabled={
              fromReferral &&
              (referralNumber?.length < 4 || referralUnit?.length === 0)
            }
          >
            {t('continueToReservation')}
          </GoldButton>
        </div>
      )}
    </div>
  );
}

export default ReservationOnboarding;

/* eslint-disable @nx/enforce-module-boundaries */
import styles from './reservation-form.module.scss';
import { useContext, useState } from 'react';
import { onFileChange } from '@orascom/utils';
import { ReservationStateEnum } from '@orascom/api-interfaces';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import { useTranslation } from 'react-i18next';
import downloadIcon from '../../assets/icons/download-2.svg';
import signatureIcon from '../../assets/icons/signature.svg';
import uploadIcon from '../../assets/icons/upload.svg';
import {
  GoldButton,
  Loader,
  NavyButton,
  RESERVATION_CONTEXT,
} from '@orascom/common-components';
import { Unit as UnitApi } from '../../api/unit.api';
import { Unit as UnitUtils } from '../../utils/unit.utils';
import { toast } from 'react-toastify';

export function ReservationForm() {
  const [selectedFilename, setSelectedFilename] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File>();
  const [loading, setLoading] = useState(false);

  const { setReservationState, sale, reservationUnitDetails } =
    useContext(RESERVATION_CONTEXT);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleDownloadReservationForm = async () => {
    if (sale && reservationUnitDetails) {
      setLoading(true);
      try {
        const downloadReservationFormResponse =
          await UnitApi.downloadReservationForm(
            reservationUnitDetails.id,
            sale.id,
            sale.customer_id
          );

        if (!downloadReservationFormResponse.ok) {
          // TODO: Add a meaningful error message
          throw new Error();
        }
        // Extract filename from header
        const filename = downloadReservationFormResponse.headers
          ?.get('Content-Disposition')
          ?.split(';')
          ?.find((n) => n.includes('filename='))
          ?.replace('filename=', '')
          .trim();

        // Get Form File Blob & generate a downloadable file
        const responseBlob = await downloadReservationFormResponse.blob();
        const formFileBlob = new Blob([responseBlob]);
        const url = window.URL.createObjectURL(formFileBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename ?? 'reservation-form.pdf');
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(error);
        toast.error('Reservation form not ready yet');
      }
      setLoading(false);
    }
  };

  const handleUploadSignedReservationForm = () => {
    if (reservationUnitDetails && sale && selectedFile) {
      setLoading(true);

      Promise.all([
        UnitUtils.reserveAttachment(
          reservationUnitDetails.id,
          sale.id,
          '1',
          selectedFile
        ),
        UnitUtils.finishUnitReservation(reservationUnitDetails.id, sale.id),
      ])
        .then(() => {
          setReservationState?.(ReservationStateEnum.RESERVATION_FORM_DONE);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles['wrapper']}>
      <h5 className={styles['title']}>{t('signTheReservationForm')}</h5>

      <div className={styles['sub']}>
        <h6>
          <img src={downloadIcon} role="presentation" alt="" />
          <span>{t('downloadTheReservationForm')}</span>
        </h6>
        <p>{t('downloadTheReservationFormDesc')}</p>
        <GoldButton
          className={styles['download']}
          onClick={(e) => {
            e.stopPropagation();
            handleDownloadReservationForm();
          }}
        >
          {t('downloadReservationForm')}
        </GoldButton>
      </div>

      <div className={styles['sub']}>
        <h6>
          <img src={signatureIcon} role="presentation" alt="" />
          <span>{t('signYourReservationForm')}</span>
        </h6>
        <p>{t('signYourReservationFormDesc')}</p>
      </div>
      <div className={styles['sub']}>
        <h6>
          <img src={uploadIcon} role="presentation" alt="" />
          <span>{t('uploadYourSignedReservationForm')}</span>
        </h6>
        <p>{t('uploadYourSignedReservationFormDesc')}</p>
      </div>

      <div className="input-wrapper">
        <label>{t('signedReservationForm')}*</label>
        <label htmlFor="file-upload" className={styles['file-upload']}>
          {selectedFilename.length
            ? selectedFilename
            : t('selectFilePlaceholder')}
          <span className={styles['upload']}>
            <img src={uploadIcon} alt="" role="presentation" />
          </span>
        </label>
        <input
          name="file-upload"
          id="file-upload"
          type="file"
          accept=".pdf,.docx"
          placeholder={t('selectFilePlaceholder')}
          onChange={(e) =>
            onFileChange(e, setSelectedFilename, setSelectedFile)
          }
        />
      </div>
      <div className={styles['buttons']}>
        <GoldButton
          onClick={handleUploadSignedReservationForm}
          disabled={!selectedFile}
        >
          {t('finishReservation')}
        </GoldButton>
        <NavyButton onClick={() => navigate(ROUTES.Home.path)}>
          {t('saveAndContinueLater')}
        </NavyButton>
      </div>
    </div>
  );
}

export default ReservationForm;

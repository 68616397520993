import { CountriesEnum, UnitPaymentTerms } from '@orascom/api-interfaces';
import { useState } from 'react';

export function usePaymentPlanCalculator() {
  const [selectedPaymentTerms, setSelectedPaymentTerms] =
    useState<UnitPaymentTerms | null>(null);

  const onSelectPaymentPlanName = (
    passedValue: string,
    paymentTerms: UnitPaymentTerms[]
  ) => {
    const availablePaymentTerms = paymentTerms.filter(
      (term) => term.paymentplan_name === passedValue
    );
    const availablePaymentTermsWithSelectedDuration =
      availablePaymentTerms.filter(
        (term) =>
          term.installment_durationin_years ===
          selectedPaymentTerms?.installment_durationin_years
      );

    if (!availablePaymentTermsWithSelectedDuration.length) {
      setSelectedPaymentTerms(availablePaymentTerms[0]);
      return;
    }
    setSelectedPaymentTerms(availablePaymentTermsWithSelectedDuration[0]);
  };

  const onSelectDownPayment = (
    downPayment: number,
    paymentTerms: UnitPaymentTerms[]
  ) => {
    const availablePaymentTerms = paymentTerms.filter(
      (term) => term.downpayment_percent === downPayment
    );
    const availablePaymentTermsWithSelectedDuration =
      availablePaymentTerms.filter(
        (term) =>
          term.installment_durationin_years ===
          selectedPaymentTerms?.installment_durationin_years
      );

    if (!availablePaymentTermsWithSelectedDuration.length) {
      setSelectedPaymentTerms(availablePaymentTerms[0]);
      return;
    }

    const availablePaymentTermsWithSelectedFreq =
      availablePaymentTermsWithSelectedDuration.filter(
        (term) =>
          term.installment_frequency ===
          selectedPaymentTerms?.installment_frequency
      );

    if (!availablePaymentTermsWithSelectedFreq.length) {
      setSelectedPaymentTerms(availablePaymentTermsWithSelectedDuration[0]);
      return;
    }

    setSelectedPaymentTerms(availablePaymentTermsWithSelectedDuration[0]);
  };

  const onSelectFrequency = (
    freq: number,
    paymentTerms: UnitPaymentTerms[]
  ) => {
    const availablePaymentTerms = paymentTerms.filter(
      (term) => term.installment_frequency === freq
    );

    const availablePaymentTermsWithSelectedDuration =
      availablePaymentTerms.filter(
        (term) =>
          term.installment_durationin_years ===
          selectedPaymentTerms?.installment_durationin_years
      );

    if (!availablePaymentTermsWithSelectedDuration.length) {
      setSelectedPaymentTerms(availablePaymentTerms[0]);
      return;
    }

    const availablePaymentTermsWithSelectedDownPayment =
      availablePaymentTermsWithSelectedDuration.filter(
        (term) =>
          term.downpayment_percent === selectedPaymentTerms?.downpayment_percent
      );

    if (!availablePaymentTermsWithSelectedDownPayment.length) {
      setSelectedPaymentTerms(availablePaymentTermsWithSelectedDuration[0]);
      return;
    }

    setSelectedPaymentTerms(availablePaymentTermsWithSelectedDownPayment[0]);
  };

  const onSelectDuration = (freq: number, paymentTerms: UnitPaymentTerms[]) => {
    const availablePaymentTerms = paymentTerms.filter(
      (term) => term.installment_durationin_years === freq
    );

    const availablePaymentTermsWithSelectedDownPayment =
      availablePaymentTerms.filter(
        (term) =>
          term.downpayment_percent === selectedPaymentTerms?.downpayment_percent
      );

    if (!availablePaymentTermsWithSelectedDownPayment.length) {
      setSelectedPaymentTerms(availablePaymentTerms[0]);
      return;
    }

    const availablePaymentTermsWithSelectedFreq =
      availablePaymentTermsWithSelectedDownPayment.filter(
        (term) =>
          term.installment_frequency ===
          selectedPaymentTerms?.installment_frequency
      );

    if (!availablePaymentTermsWithSelectedFreq.length) {
      setSelectedPaymentTerms(availablePaymentTermsWithSelectedDownPayment[0]);
      return;
    }

    setSelectedPaymentTerms(availablePaymentTermsWithSelectedFreq[0]);
  };

  const showBeforeInstallment = (
    displayedPaymentDetails: Partial<UnitPaymentTerms>
  ) =>
    Boolean(
      displayedPaymentDetails.total_number_of_installments_before_delivery
    ) &&
    Boolean(
      displayedPaymentDetails?.unit_installment_before_delivery_per_frequency
    );

  const showAfterInstallment = (
    displayedPaymentDetails: Partial<UnitPaymentTerms>
  ) =>
    Boolean(
      displayedPaymentDetails.total_number_of_installments_after_delivery
    ) &&
    Boolean(
      displayedPaymentDetails?.unit_installment_after_delivery_per_frequency
    );

  const showInstallmentAmount = (
    displayedPaymentDetails: Partial<UnitPaymentTerms>
  ) => Boolean(displayedPaymentDetails?.installment_amount);

  const showDeliveryPercent = (
    displayedPaymentDetails: Partial<UnitPaymentTerms>
  ) => Boolean(displayedPaymentDetails?.delivery_percent);

  const hideUnitPrice = (displayedPaymentDetails: Partial<UnitPaymentTerms>) =>
    !displayedPaymentDetails?.unit_price &&
    !displayedPaymentDetails?.discount_amount &&
    !displayedPaymentDetails?.price_after_discount;

  const hideUnitInstallments = (
    displayedPaymentDetails: Partial<UnitPaymentTerms>,
    country: CountriesEnum | undefined
  ) =>
    country !== CountriesEnum.OMAN
      ? !displayedPaymentDetails?.first_installment_amount &&
        !showDeliveryPercent(displayedPaymentDetails) &&
        !displayedPaymentDetails?.delivery_amount &&
        !showBeforeInstallment(displayedPaymentDetails) &&
        !showAfterInstallment(displayedPaymentDetails)
      : !showInstallmentAmount(displayedPaymentDetails) &&
        !showDeliveryPercent(displayedPaymentDetails);

  const hideCompareUnitInstallments = (
    displayedPaymentDetails: Partial<UnitPaymentTerms>
  ) =>
    !showBeforeInstallment(displayedPaymentDetails) &&
    !showAfterInstallment(displayedPaymentDetails);

  return {
    onSelectPaymentPlanName,
    onSelectDownPayment,
    onSelectFrequency,
    onSelectDuration,
    selectedPaymentTerms,
    setSelectedPaymentTerms,
    showBeforeInstallment,
    showAfterInstallment,
    showInstallmentAmount,
    showDeliveryPercent,
    hideUnitPrice,
    hideUnitInstallments,
    hideCompareUnitInstallments,
  };
}

export default usePaymentPlanCalculator;
